// Types
import { UserType } from '../../types/user.type';

// Styles
import './nick.styles.scss';

/**
 * Nick Component
 * @description Component to show the nick of user
 * @returns {JSX.Element}
 */
export function NickComponent(properties: {
    user: UserType | undefined
}): JSX.Element {

    return <div className="NickComponent">
        <strong>{properties?.user?.name} {properties?.user?.surnames}</strong>
    </div>

}
