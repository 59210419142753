import React from "react";

import "./mutedWidget.styles.scss";

interface DishMutedWidgetProperties {
  layout?: string;
}

export function DishMutedWidget(
  props: DishMutedWidgetProperties
): React.ReactElement {
  return (
    <div className={`DishMutedWidget ${props.layout || ""}`}>
      <div>
        <i className="las la-microphone-slash" />
      </div>
    </div>
  );
}

export default DishMutedWidget;
