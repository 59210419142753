// Modules
import { useState, useEffect } from 'react';

// Styles
import './clock.styles.scss';

/**
 * Clock Component
 * @description This component shows the current time and date
 * @returns {JSX.Element}
 */
export function ClockComponent() {

    // current date
    const [date, setDate] = useState(new Date());

    // refresh clock
    function refreshClock() {
        setDate(new Date());
    }

    useEffect(() => {

        // refresh clock every second
        const timerId = setInterval(refreshClock, 1000);

        // clear interval
        return function cleanup() {
            clearInterval(timerId);
        };

    }, []);

    return <>
        <div
            className='ClockBackgroundComponent'
            style={{
                backgroundImage: 'url(/images/home.jpg)',
            }}
        />

        <div className='ClockComponent'>
            <div className="hour">
                {
                    date.toLocaleTimeString('es-ES', {
                        hour: 'numeric',
                        minute: '2-digit',
                    })
                }
            </div>
            <div className="day">
                {
                    date.toLocaleDateString('es-ES', {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'long',
                    })
                }
            </div>
        </div>
    </>

}
