import React, { useState } from 'react';
import './step-slider.styles.scss';

export interface StepSliderComponentProps {
  title: string;
  steps: number;
  values: number[];
  selectedValue: number;
  setSelectedValue: (volume: number) => void;
}

export const StepSliderComponent = (props: StepSliderComponentProps) => {
  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setSelectedValue(props.values[parseInt(event.target.value) - 1]);
  };

  return <>
    <div className='StepSliderComponent container'>
      <div className='container'>
        <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
      }}>
          <i className="las la-minus" onClick={
            () => {
              const index = props.values.indexOf(props.selectedValue) - 1;
              if (index >= 0) {
                props.setSelectedValue(props.values[index]);
              }
            }
          }
          aria-disabled={props.values.indexOf(props.selectedValue) === 0}
          ></i>
          <input
            type='range'
            min='1'
            max={props.steps}
            value={props.values.indexOf(props.selectedValue) + 1}
            step='1'
            className={`n n${props.steps}`}
            onChange={handleSliderChange}
          />
          <i className="las la-plus" onClick={
            () => {
              const index = props.values.indexOf(props.selectedValue) + 1;
              if (index < props.values.length) {
                props.setSelectedValue(props.values[index]);
              }
            }
          }
          aria-disabled={props.values.indexOf(props.selectedValue) === props.values.length - 1}
          ></i>
        </div>
      </div>
    </div>
  </>

}
