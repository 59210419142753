export class LocalStorageHelper {

  /**
   * Remove all items from the local storage
   */
  static async cleanLocalStorage() {
    const localStorageItems = [
      'audioOutputChanelName',
      'audioOutputDeviceLabel',
      'audio_status',
      'backgoundSelected',
      'canRecord',
      'localCall',
      'user',
      'user_settings',
      'video_status',
      'audioInputDeviceId',
    ];

    localStorageItems.forEach((item) => {
      localStorage.removeItem(item);
    });

    // Remove videoDeviceId only if the app is running in the browser and not in electron
    // @ts-ignore
    !window.electron && localStorage.removeItem('videoDeviceId');
  }

}
