// Modules
import axios from 'axios';
import { useState } from 'react';
import { environment } from '../../environment';

// Types
import { UserType } from '../../types/user.type';
import { ErrorsType } from '../errors/errors.type';

// Components
import { ErrorsComponent } from '../errors/errors.component';
import { LoaderComponent } from '../loader/loader.component';

// Styles
import './schedule.styles.scss';
import { useTranslation } from 'react-i18next';

/**
 * Schedule Delete Component
 * @description Screen to remove a schedule from the platform
 * @returns {JSX.Element}
 */
export function ScheduleDeleteComponent(properties: {
    setScreen: (screen: string) => void
    user: UserType
    updateData: () => void
    id: string
}): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // errors in delete request
    const [errors, setErrors] = useState<ErrorsType>({})

    // success in delete request
    const [success, setSuccess] = useState<boolean>(false)

    // loading of axios request
    const [loading, setLoading] = useState<boolean>(false)

    const onSubmit = () => {

        // show loading
        setLoading(true)

        // clean errors
        setErrors({})

        // delete schedule
        axios.delete(`${environment.api.host}/api/meet/${properties.id}`, {
            headers: {
                Authorization: `Bearer ${properties.user.access_token}`
            }
        }).then((response) => {

            // delay to show success
            setTimeout(() => {

                // hide loading
                setLoading(false)

                // show success
                setSuccess(true)

                // update data
                properties.updateData();

            }, 1000);
        })
            .catch((error) => {

                // delay to show errors
                setTimeout(() => {

                    // hide loading
                    setLoading(false)

                    // get errors
                    const errors = error?.response?.data?.errors;

                    // set errors
                    if (errors) setErrors(errors)

                    // define general error
                    else setErrors({ general: ['Error de conexión'] })

                }, 1000);
            })
    }

    return <div className="ScheduleComponent Modal">

        {/* header of schedule delete modal */}
        <div className='Header'>
            <b>{t('schedule.delete.title')}</b>
            <i className='las la-times' onClick={() => { properties.setScreen('list') }} />
        </div>

        {/* form of schedule delete modal */}
        <div
            className='Form'
            style={{
                paddingTop: 10,
            }}>
            {
                success && <div className='Success'>
                    <i className="las la-check"></i>
                    <p>
                        {t('schedule.delete.success')}
                    </p>
                </div>
            }
            <ErrorsComponent errors={errors} name="general" />
            <div className='Information'>
              <span>{t('schedule.delete.information')} <b>Sippar</b> {t('schedule.delete.information2')}</span>
            </div>
            {
                !success && <div className='Submit-position'>
                    <div className='Submit' onClick={onSubmit} >
                        {t('schedule.delete.submit')}
                    </div>
                </div>
            }
        </div>
        <LoaderComponent status={loading} />
    </div>

}
