import { useMemo } from "react";

/*
  Hook para parsear la URL y obtener los valores de controller y action que
  son los únicos valores obligatorios que debería tener una URL.

  Ejemplo 1:
  https://www.example.com/mails/confirm/1?token=123#fragment
    |
    --> { controller: 'mails', action: 'confirm', id: '1', query: { token: '123' }, fragment: 'fragment' }

  Ejemplo 2:
  https://www.example.com/mails/confirm.html?token=123#fragment
    |
    --> { controller: 'mails', action: 'confirm', _ext: 'html', query: { token: '123' }, fragment: 'fragment' }

  Ejemplo 3:
  https://www.example.com/mails/confirm/1/2?token=123#fragment
    |
    --> { controller: 'mails', action: 'confirm', id_1: '1', id_2: '2', query: { token: '123' }, fragment: 'fragment' }

  Como se muestra en los ejemplos, el hook es capaz de parsear la URL y obtener
  los valores de controller y action, además de cualquier otro valor que se le
  haya definido en la URL.

  Los valores después de controller y action pueden ser múltiples, pero deben
  estar separados por un '/'. Estos valores se pueden asignar a variables
  con un nombre personalizado, por ejemplo, para la URL:

  https://www.example.com/mails/confirm/1/2?token=123#fragment

  Se puede mail_id para el valor 1 y user_id para el valor 2, entonces
  el hook devolverá:

  { controller: 'mails', action: 'confirm', mail_id: '1', user_id: '2', query: { token: '123' }, fragment: 'fragment' }
*/

type IUrlIdentifiers<T extends string[]> = {
  [K in T[number]]: string | undefined;
};

function useParsedUrl<T extends string[]>(
  url: string | URL,
  keys?: Required<T>
) {
  return useMemo(() => {
    const urlObj = new URL(
      typeof url === "string" ? url : url.href,
      window.location.origin
    );

    // Extraer parámetros de consulta (?)
    const query = urlObj.searchParams;

    // Separar segmentos del path y filtrar los valores vacíos
    const pathSegments = urlObj.pathname.split("/").filter(Boolean);

    // Extraer controller y action
    let controller: string | undefined = pathSegments[0] || undefined;
    let action: string | undefined = pathSegments[1] || undefined;

    // Extraer extensión
    let ext: string | undefined;
    if (action && action.includes(".")) {
      const [actionName, extension] = action.split(".");

      action = actionName;
      ext = extension;
    }

    // Extraer identificadores personalizados
    const pathIdentifiers = pathSegments.slice(2);
    let identifiers: IUrlIdentifiers<T> | undefined;
    if (keys) {
      identifiers = keys.reduce((acc, key, index) => {
        acc[key as T[number]] = pathIdentifiers[index];
        return acc;
      }, {} as IUrlIdentifiers<T>);
    }

    return {
      controller,
      action,
      identifiers,
      ext,
      query,
      fullBase: urlObj.href,
    };
  }, [url, keys]);
}

export default useParsedUrl;
