// Styles
import './autodisabled.styles.scss'

/**
 * Autodisabled Component
 * @description This component disables the autocompletion of the entries
 * @returns {JSX.Element}
 */
export function AutodisabledComponent() {

    return <div
        className='AutodisabledComponent'
    >
        <input
            type={'text'}
            name={'email'}
        />
        <input
            type={'password'}
            name={'password'}
        />
    </div>

}