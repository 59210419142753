import { useState } from 'react';
import './userInfo.styles.scss';
import { useTranslation } from 'react-i18next';


export interface UserInfoComponentProps {
  name: string;
  setShowLogout: (showLogout: boolean) => void;
  setConfigOption: (configOption: string) => void;
  setScene: (scene: string | undefined) => void;
}

export function UserInfoComponent(properties: UserInfoComponentProps) {
  const [isMenuVisible, setMenuVisible] = useState<boolean>(false);

  const { t } = useTranslation();

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      <div className="UserInfoComponent" onClick={toggleMenu}>
        <div className='userInfo'>
          {properties.name} <i className="las la-user-circle"></i>
        </div>
        {isMenuVisible && (
          <div className='dropdown-menu'>
            <div className='dropdown-item' onClick={
              () => {
                properties.setScene(undefined);

                setTimeout(() => {
                  properties.setScene('edit-profile');
                  properties.setConfigOption('name');
                }, 100);
              }
            }>
              <i className="las la-user"></i>
              <span>{t('dropdown-menu.edit-profile')}</span>
            </div>
            <div className='dropdown-item' onClick={
              () => {
                properties.setScene(undefined);

                setTimeout(() => {
                  properties.setScene('edit-profile');
                  properties.setConfigOption('password');
                }, 100);
              }
            }>
              <i className="las la-lock"></i>
              <span>{t('dropdown-menu.change-password')}</span>
            </div>
            <div className='dropdown-item' onClick={
              () => {
                properties.setScene(undefined);

                setTimeout(() => {
                  properties.setScene('edit-profile');
                  properties.setConfigOption('other-options');
                }, 100);
              }
            }>
              <i className="las la-cog"></i>
              <span>{t('dropdown-menu.user-settings')}</span>
            </div>
            <div className='dropdown-item logout' onClick={() => {
              properties.setScene(undefined);
              properties.setShowLogout(true)
              }}>
              <i className="las la-power-off"></i>
              <span>{t('dropdown-menu.logout')}</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
