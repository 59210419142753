import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import successAnimation from "../../lotties/success.json";
import failAnimation from "../../lotties/fail.json";
import warningAnimation from "../../lotties/warning.json";

import "./modal.styles.scss";
import { useTranslation } from "react-i18next";

export interface ModalProps {
  isOpen: boolean;
  type: "success" | "error" | "warning";
  onClose: () => void;
  children?: React.ReactNode;
  body?: {
    title: string;
    content: string;
  };
  buttons?: {
    text: string;
    class: string;
    onClick: () => void;
  }[];
}

export function Modal({ isOpen, type, onClose, body, children, buttons }: ModalProps) {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(isOpen);

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  if (!showModal) return null;

  return (
    <div className="modal-overlay">
      <div
        className={`modal-content`}

        onClick={(e) => e.stopPropagation()}
      >
        {children ? (
          children
        ) : (
          <>
          <div className="modal-content__default">
            <div className="modal-content__icon">
              {type === "success" && (
                <Lottie
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: successAnimation,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  height={95}
                  width={95}
                />
              )}

              {type === "error" && (
                <Lottie
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: failAnimation,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  height={80}
                  width={80}
                />
              )}

              {type === "warning" && (
                <Lottie
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: warningAnimation,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  height={80}
                  width={80}
                />
              )}
            </div>
            <div className="modal-content__info">
              <h2 style={{ textAlign: "center" }}>{body?.title}</h2>

              <p className="content" style={{ textAlign: "center", marginTop: 0 }}>{body?.content}</p>
            </div>

            <div className="modal-content__buttons">
              {
                buttons && (
                  buttons.map((button, index) => (
                    <button
                      key={index}
                      className={button.class}
                      onClick={button.onClick}
                    >
                      {button.text}
                    </button>
                  ))
                )
              }
              {
                !buttons && (
                  <button className="close" onClick={onClose}>
                    {t('button.modal.close')}
                  </button>
                )
              }
            </div>
          </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Modal;
