// Modules
import axios from 'axios';
import { useEffect, useState } from 'react';

// Environment variables
import { environment } from '../../environment';

// Components
import { AutodisabledComponent } from '../autodisabled/autodisabled.component';
import { ClockComponent } from '../clock/clock.component';
import { ErrorsComponent } from '../errors/errors.component';
import { LoaderComponent } from '../loader/loader.component';

// Types
import { ErrorsType } from '../errors/errors.type';
import { RegisterProperties } from './register.properties';
import { Trans, useTranslation } from 'react-i18next';
import PasswordInput from '../password/password-input.component';
import Modal from '../modals/modal.component';

/**
 * Register Component
 * @description Component to register in the platform
 * @param {RegisterProperties} properties
 * @returns {JSX.Element}
 */
export function RegisterComponent(properties: RegisterProperties): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // errors of axios request to register in the platform
    const [errors, setErrors] = useState<ErrorsType | undefined>()

    // email of user
    const [email, setEmail] = useState<string>()

    // password of user
    const [password, setPassword] = useState<string>()

    // repeat password of user
    const [repeatPassword, setRepeatPassword] = useState<string>()

    // name of user
    const [name, setName] = useState<string>()

    // surname of user
    const [surname, setSurname] = useState<string>()

    // invitation code of user
    const [invitation, setInvitation] = useState<string>()

    // Boolean to accept terms and conditions
    const [legal, setLegal] = useState<boolean>(false)

    // loading of axios request
    const [loading, setLoading] = useState<boolean>(false)

    // success of axios request
    const [success, setSuccess] = useState<boolean>(false)

    // submit attempt
    const [submit, setSubmit] = useState<boolean>(false)

    const [passwordsNotMatch, setPasswordsNotMatch] = useState<boolean>(false);

    useEffect(() => {
      if (password && repeatPassword && password !== repeatPassword) {
        setErrors({
          passwordsNotMatch: [t('form.errors.passwords')]
        })
        setPasswordsNotMatch(true)
      } else {
        setErrors(undefined)
        setPasswordsNotMatch(false)
      }
    }, [password, repeatPassword]);

    const registerSubmit = () => {
        if (passwordsNotMatch) return;

        // set submit attempt
        setSubmit(true)

        // show loading
        setLoading(true)

        // clean errors
        setErrors(undefined)

        // register axios request
        axios.post(`${environment.api.host}/api/user`, {
            name: name,
            surname: surname,
            invitation: invitation,
            legal: legal,
            email: email,
            password: password
        }).then((response) => {

            // delay to show success
            setTimeout(() => {

                // hide loading
                setLoading(false)

                // set success
                setSuccess(true)

                properties.onSuccess(response.data.items[0].email_confirmed)

            }, 200);
        })
            .catch((error) => {

                // delay to show errors
                setTimeout(() => {

                    // hide loading
                    setLoading(false)

                    // get errors
                    const errors = error?.response?.data?.errors;

                    // set errors
                    if (errors) setErrors(errors)

                    // define general error
                    else setErrors({ general: ['Error de conexión'] })

                }, 200);
            })
    }

    return <div className="LoginComponent ">
        <AutodisabledComponent />
        <div className='box'>
            <LoaderComponent status={loading} />

            <h2>
                {t('register.title.start')} <b>Sippar</b> {t('register.title.end')}
            </h2>
            {
                success && <>
                    <div className='Alternative'>
                      <Trans
                        i18nKey="register.success.alternative" // optional -> fallbacks to defaults if not provided
                        components={{ br: <br />, b: <b /> }}
                      />
                    </div>
                    <div className='Submit secondary' onClick={() => {
                        properties.setScene('login')
                    }}>
                        {
                            t('register.login')
                        }
                    </div>
                </>
            }
            {!success && <>
                <ErrorsComponent errors={errors} name="general" />
                <div className='Input'>
                    <input type="text" placeholder={`${t('register.invitation')}`}
                        onChange={(event) => {
                            setInvitation(event.target.value)
                        }}
                        value={invitation}
                    />

                </div>
                <ErrorsComponent errors={errors} name="invitation" />
                <div className='Pair'>
                    <div className='Input'>
                        <input type="text" placeholder={`${t('register.name')}`}
                            onChange={(event) => {
                                setName(event.target.value)
                            }}
                            value={name}
                        />
                        <ErrorsComponent errors={errors} name="name" />
                    </div>
                    <div className='Input'>
                        <input type="text" placeholder={`${t('register.surname')}`}
                            onChange={(event) => {
                                setSurname(event.target.value)
                            }}
                            value={surname}
                        />
                        <ErrorsComponent errors={errors} name="surname"

                        />
                    </div>
                </div>


                <div className='Input'>
                    <i className="las la-envelope"></i>
                    <input type="text" placeholder={`${t('register.email')}`}
                        onChange={(event) => {
                            setEmail(event.target.value)
                        }}
                        value={email}
                    />

                </div>
                <ErrorsComponent errors={errors} name="email" />

                <PasswordInput name={'password'} providers={[ { name: 'local' } ]} placeholder={`${t('register.password')}`} setPassword={setPassword} checkPassword={true} submitAttempt={submit}/>

                <ErrorsComponent errors={errors} name="password" />

                <PasswordInput name={'repeat-password'} providers={[ { name: 'local' } ]} placeholder={`${t('register.repeatPassword')}`} setRepeatPassword={setRepeatPassword}/>

                <ErrorsComponent errors={errors} name="passwordsNotMatch" />

                <div className={`Checkbox ${legal ? 'active' : ''}`} onMouseDown={() => {
                    setLegal(!legal)
                }}>
                    <i className={
                        legal ? 'las la-check' : '"las la-times"'
                    }></i>
                    <span>
                        {`${t('register.legal')} `}
                        <a
                            onMouseDown={(event) => {
                                event.preventDefault()
                                const path = 'https://comitas.es/aviso-legal/'
                                window.open(path, '_blank')
                            }
                            }

                            href='https://comitas.es/aviso-legal/' target={'_blank'} rel={'noreferrer'}>{t('register.conditions')}</a></span>
                </div>
                <ErrorsComponent errors={errors} name="legal" />

                <div className='Submit' onMouseDown={registerSubmit}>
                    {t('register.submit')}
                </div>
                <div className='Alternative'>
                    {t('register.alternative')}
                </div>
                <div className='Submit secondary' onClick={() => {
                    properties.setScene('login')
                }}>
                    {t('register.login')}
                </div>
            </>}

        </div>

        {/* Clock rendered in the record home*/}
        <ClockComponent />
    </div>
}
