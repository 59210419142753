// Modules
import { useEffect, useRef } from 'react'

// Components
import { FormComponent } from './components/form/form.component'
import { MessageComponent } from './components/message/message.component'

// Properties
import { ChatProperties } from './chat.properties'

// Styles
import './chat.styles.scss'

/**
 * Chat Component
 * @description Component to render chat of videocall
 * @param {ChatProperties} settings
 * @returns {JSX.Element}
 */
export function ChatComponent(settings: ChatProperties) {

    // ref messages
    const messagesRef = useRef<HTMLDivElement>(null)

    // scroll to bottom
    const scrollToBottom = () => {

        if (messagesRef.current) {

            // scroll to bottom smooth
            messagesRef.current.scrollTo({
                top: messagesRef.current.scrollHeight,
                behavior: 'smooth'
            })

        }

    }
    // scroll to bottom
    useEffect(() => {

        scrollToBottom()

    }, [settings.messages])
    return <div className="Modal ChatComponent">

        <div className='Header'>
            Chat
            <i className="las la-times last" onClick={() => {

                settings.setSection()

            }} />
        </div>
        <div className='messages'
            ref={messagesRef}
        >
            {
                settings?.messages && settings?.messages.map((message: any, index: any) => {

                    return <MessageComponent key={index} message={message} />

                })
            }
        </div>
        <FormComponent sendMessage={settings?.sendMessage} room={settings.room} />
    </div>

}