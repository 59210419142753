import { useEffect } from "react";
import { DishResize } from "./dish.layout";

/**
 * Resize Component
 * @description This component is oriented to resize page elements using window to improve performance
 * @returns {JSX.Element}
 */
export function ResizeComponent(): JSX.Element {

    const dishResize = () => {

        new DishResize('DishScenary', 'DishScenary', 'DishVideoComponent').resize();

    }

    useEffect(() => {

        dishResize()

        const interval = setInterval(() => dishResize(), 1000);
        window.addEventListener('resize', dishResize);

        return () => {

            clearInterval(interval);
            window.removeEventListener('resize', dishResize);

        }

    }, [])
    return <></>
}
