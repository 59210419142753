import { useTranslation } from "react-i18next";
import { ChangePasswordProperties } from "./change-password.properties";
import { useState, useEffect } from "react";
import axios from "axios";
import { environment } from "../../environment";
import { ErrorsType } from "../errors/errors.type";
import { ErrorsComponent } from "../errors/errors.component";
import { ClockComponent } from "../clock/clock.component";
import { LoaderComponent } from "../loader/loader.component";
import PasswordInput from "../password/password-input.component";


export function ChangePasswordComponent(properties: ChangePasswordProperties): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // errors of change password request
    const [errors, setErrors] = useState<ErrorsType>({})

    // password of user
    const [password, setPassword] = useState<string>('');

    // repeat password of user
    const [repeatPassword, setRepeatPassword] = useState<string>('');

    // loading of axios request
    const [loading, setLoading] = useState<boolean>(false);

    // success of change password request
    const [changePasswordSuccess, setChangePaswordSuccess] = useState<{
      success: boolean,
      message: string
    }>({ success: false, message: `No se ha establecido la contraseña` });

    // submit attempt
    const [submit, setSubmit] = useState<boolean>(false);

    const [passwordsNotMatch, setPasswordsNotMatch] = useState<boolean>(false);

    useEffect(() => {
      if (password && repeatPassword && password !== repeatPassword) {
        setErrors({
          passwordsNotMatch: [t('form.errors.passwords')]
        })
        setPasswordsNotMatch(true)
      } else {
        setErrors({})
        setPasswordsNotMatch(false)
      }
    }, [password, repeatPassword]);

    const handleChangePasswordSuccess = () => {
      properties.setScene('login');
      properties.onSuccess();
    }

    const changePasswordSubmit = () => {
      if (passwordsNotMatch) return;

      // set submit attempt
      setSubmit(true);

      // show loading
      setLoading(true);

      // clean errors
      setErrors({});

      // login axios request
      axios.put(`${environment.api.host}/api/user/set-password/${properties.user?.id}`, {
        password: password
      }).then((response) => {

          // delay to show success
          setTimeout(() => {

              // hide loading
              setLoading(false);

              // set success
              setChangePaswordSuccess({
                success: true,
                message: response.data.message || `Se ha establecido la contraseña correctamente`
              });

              handleChangePasswordSuccess();

          }, 1000)
        }).catch((error) => {

            // hide loading
            setLoading(false);

            // get errors
            const errors = error?.response?.data?.errors;

            // set request errors
            if (errors) setErrors(errors);

            // define general error
            else setErrors({ general: ['Error de conexión'] });
        })
    }

    return (
        <div className='ForgotComponent'>

          <div className='box'>
            <h2>{t('change-password.title')} <b>Sippar</b> </h2>

            <p>{t('change-password.message')}</p>

            <ErrorsComponent errors={errors} name="general" />

            <PasswordInput name={'password'} providers={[ { name: 'local' } ]} placeholder={t('change-password.password')} setPassword={setPassword} checkPassword={true} submitAttempt={submit}/>
            <ErrorsComponent errors={errors} name="password" />

            <PasswordInput name={'repeatPassword'} providers={[ { name: 'local' } ]} placeholder={t('change-password.repeatPassword')} setPassword={setRepeatPassword} />
            <ErrorsComponent errors={errors} name="passwordsNotMatch" />

            <ErrorsComponent errors={errors} name="passwordMatch" />

            <div className='Submit' style={{ scale: "0.8" }} onClick={changePasswordSubmit}>
              {t('change-password.submit')}
            </div>
            <LoaderComponent status={loading} />
          </div>

          {/* Clock to Render in Home Screen */}
          <ClockComponent />
        </div>
    )
}
