// Modules
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useTranslation } from 'react-i18next';

// Environment variables
import { environment } from '../../environment';

// Types
import { UserType } from '../../types/user.type';
import { ParticipantType, PatientType } from '../directory/directory.types';
import { InvitationType, MeetType } from './schedule.types';

// Components
import { LoaderComponent } from '../loader/loader.component';

/**
 * Meet Participants Component
 * @description Screen to select participants of a meet
 * @param properties
 * @returns {JSX.Element}
 */
export function MeetParticipantsComponent(
    properties: {
        setSubscreen: (screen: string) => void,
        user: UserType
        meet: MeetType

        setScreen: (screen: string) => void
    }
): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // author
    const [author, setAuthor] = useState<InvitationType>();

    // list of all participants
    const [allParticipants, setParticipants] = useState<ParticipantType[]>([]);

    // list of selected participants
    const [selectedParticipants, setSelectedParticipants] = useState<ParticipantType[]>([]);

    // search text
    const [search, setSearch] = useState<string>('');

    // loading
    const [loading, setLoading] = useState<boolean>(false);

    // search text debounced
    const [searchText] = useDebounce(search, 1000);

    // error in server
    const [isServerError, setIsServerError] = useState<boolean>(false);

    const fetchInitialData = async () => {
      setLoading(true);

      try {
        const [participants, invitations] = await Promise.all([
          axios.get(`${environment.api.host}/api/user?search=${searchText}`, {
            headers: {
              Authorization: `Bearer ${properties.user.access_token}`
            }
          }),
          axios.get(`${environment.api.host}/api/invitation/${properties.meet.id}`, {
            headers: {
              Authorization: `Bearer ${properties.user.access_token}`
            }
          })
        ]);

        setParticipants(participants.data.items.map((item: ParticipantType) => {
          return {
            id: item.id,
            name: item.name,
            surnames: item.surnames,
            email: item.email,
            phone: item.phone,
            presence_on_meeting: invitations.data.items.find((invitation: InvitationType) => {
              return invitation.userByGuestId.id === item.id
            }) ? true : selectedParticipants.find((participant: ParticipantType) => {
              return participant.id === item.id
            }) ? true : false
          }
        }));

        setAuthor(invitations.data.items.find((item: InvitationType) => {
          return item.userByGuestId.id === properties.user.id
        }));

        setLoading(false);
        setIsServerError(false);
      } catch (error: any) {

        setLoading(false);

        if (error.response?.status === 500) {
          setIsServerError(true);
        }

      }

    };

    useEffect(() => {
      fetchInitialData();
    }, [searchText]);

    useEffect(() => {
      if (selectedParticipants.length === 0) {
        const selected: ParticipantType[] = [];

        allParticipants.forEach((item: ParticipantType) => {
          if (item.presence_on_meeting) {
            selected.push(item);
          }
        });

        setSelectedParticipants(selected);
      }
    }, [allParticipants]);

    const changePresence = (modParticipant: ParticipantType) => {
      const newParticipants = allParticipants.map((participant) => {
        if (participant.id === modParticipant.id) {
          return {
            ...participant,
            presence_on_meeting: !participant.presence_on_meeting
          }
        }

        return participant;
      });

      setParticipants(newParticipants);

      if (modParticipant.presence_on_meeting) {
        setSelectedParticipants(selectedParticipants.filter((participant: ParticipantType) => {
          return participant.id !== modParticipant.id
        }));
      } else {
        setSelectedParticipants([...selectedParticipants, modParticipant]);
      }
    };

    const onSubmit = () => {
      setLoading(true);

      setTimeout(async () => {
        try {
          setLoading(false);

          await axios.post(`${environment.api.host}/api/invitation/${properties.meet.id}`, {
            participants: selectedParticipants
          }, {
            headers: {
              Authorization: `Bearer ${properties.user.access_token}`
            }
          });

          properties.setScreen('list');
        } catch (error) {
          setLoading(false);
        }
      }, 1000);
    }

    return (<div className="DirectoryComponent Modal">

        <div className='Header'>
            {t('schedule.select.title')}

            <i style={{
                marginLeft: 'auto',
            }} className='las la-times' onClick={() => { properties.setSubscreen('list') }}></i>
        </div>
        <div style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflowY: 'auto'
        }}>
            <div className='Search'>
                <i className="las la-search"></i>
                <input type="text" placeholder={`${t('schedule.select.search')}`}
                    value={search}
                    onChange={(event) => {
                        setSearch(event.target.value)
                    }}
                />
            </div>
            <LoaderComponent status={loading} />
            {
                !isServerError && <><ul
                    className='List'
                    style={{
                        minHeight: 'auto',
                        flex: 'none',
                        marginBottom: 0,
                        overflowY: 'visible',
                        paddingBottom: 0
                    }}
                >
                    {

                    allParticipants.map((participant, index) => {
                      return <li
                          key={index}
                          className={participant.id === author?.userByGuestId.id ? 'disabled' : 'clicable'}
                          onClick={participant.id !== author?.userByGuestId.id ? () => changePresence(participant) : undefined}
                      >
                          <i className={
                            (participant.id === author?.userByGuestId.id ? ' disabled ' : '')
                            +
                            (selectedParticipants.find((selected) => {
                                return selected.id === participant.id
                              })? 'las la-check' : 'square')

                            }
                            />
                          <div className="user">
                              <div className="name">
                                  {participant.name} {participant.surnames}
                              </div>
                              <div className="document">
                                  {participant.email}
                              </div>
                          </div>
                      </li>
                      })
                    }
                </ul>
                <div className='Space' />
                <div className='Submit-position'>
                    <div className='Submit' onClick={onSubmit} >
                        {t('schedule.update.submit')}
                    </div>
                </div>
                </>
              }
              {
                isServerError && <>
                <div className='Empty Column'>
                  <p>{t("schedule.select.error")}</p>
                  <div className="Submit" onClick={() => { fetchInitialData() }}>{t("schedule.select.errorbtn")}</div>
                </div>

                </>
              }
        </div>
    </div>);
}
