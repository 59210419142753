// Properties
import { useEffect, useState } from 'react';
import { ShareFileProperties } from './share-file.properties';

// Styles
import './share-file.styles.scss';
import { useTranslation } from 'react-i18next';
import { ErrorsComponent } from '../../../errors/errors.component';
import { ErrorsType } from '../../../errors/errors.type';


/**
 * Participants Component
 * @description Component to render participants of videocall
 * @param {ParticipantsProperties} properties
 * @returns {JSX.Element}
 */
export function ShareFileComponent(properties: ShareFileProperties): JSX.Element {
  const { t } = useTranslation();
  const [participantsToSend, setParticipantsToSend] = useState<string[]>([]);
  const [fileToSend, setFileToSend] = useState<File | undefined>();
  const [originalFilename, setOriginalFilename] = useState<string>('');
  const [filename, setFilename] = useState<string>('');
  const [extension, setExtension] = useState<string>('');

  const [errors, setErrors] = useState<ErrorsType>({})

  const MAX_FILE_SIZE = 10 * 1024 * 1024;
  const ALLOWED_MIMETYPES = [
    // PDF
    'application/pdf',
    // TXT
    'text/plain',
    // Word
    'application/vnd.oasis.opendocument.text',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    // Excel
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet',
    // Powerpoint
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.oasis.opendocument.presentation',
    // Images
    'image/*'
  ];

  const checkSize = (file: File) => {
    if (file.size > MAX_FILE_SIZE) {
      setErrors({
        file: [t('share-file.errors.file-size')]
      });

      return false;
    }

    return true;
  };

  const checkType = (file: File) => {
    const mimeType = file.type;

    if (!ALLOWED_MIMETYPES.includes(mimeType)
      && !ALLOWED_MIMETYPES.some(allowed => allowed.includes('*') && mimeType.includes(allowed.split('*')[0]))) {
      setErrors({
        file: [t('share-file.errors.file-type')]
      });

      return false;
    }

    return true;
  }

  const resetFileInput = () => {
    setFileToSend(undefined);
    setOriginalFilename('');
    setFilename('');
    setExtension('');
  }

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      if (!checkSize(event.target.files[0]) || !checkType(event.target.files[0])) {
        resetFileInput();

        return;
      }

      setFileToSend(event.target.files[0]);
      setOriginalFilename(event.target.files[0].name);
      setFilename(event.target.files[0].name.split('.').slice(0, -1).join('.'));
      setExtension(event.target.files[0].name.split('.').pop() || '');
    } else {
      resetFileInput();
    }

    return;
  };

  const handleSendFile = () => {
    if (!participantsToSend.length) {
      setErrors({
        participants: [t('share-file.errors.participants')]
      });

      return;
    }

    if (!fileToSend) {
      setErrors({
        file: [t('share-file.errors.file')]
      });

      return;
    }

    if (!filename) {
      setErrors({
        filename: [t('share-file.errors.filename')]
      });

      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(fileToSend as Blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      const data = {
        file: base64data,
        filename: `${filename}.${extension}`,
        participants: participantsToSend,
        user: properties.user.id,
        room: properties.room
      };

      // Send file
      properties.socket.emit('file:share', data);

      // Close modal
      properties.setScene(undefined);
    }

    return;
  };

  useEffect(() => {
    const newErrors = {...errors};

    if (participantsToSend.length) {
      delete newErrors.participants;
    }

    setErrors(newErrors);
  }, [participantsToSend]);

  return <div className="ShareFileComponent Modal">
      <div className='Header'>
          {t('share-file.title')}
          <i className='las la-times last' onClick={() => { properties.setScene(undefined) }}></i>
      </div>
      <div className='Participants'>
        <ErrorsComponent name='general' errors={errors} />
        <p>{t('share-file.participants')}</p>
        {
          Object.entries(properties.participants).map(([key, value]) => {
            return <div key={key} className='Reciver' onClick={
              () => {
                if (participantsToSend.includes(value.user.id)) {
                  setParticipantsToSend(participantsToSend.filter(participant => participant !== value.user.id));
                } else {
                  setParticipantsToSend([...participantsToSend, value.user.id]);
                }
              }
            }>
              <div className={`Checkbox ${participantsToSend.includes(value.user.id) ? 'active' : ''}`}>
                  <i className={
                      participantsToSend.includes(value.user.id) ? 'las la-check' : '"las la-times"'
                  }></i>
                  <span>{value.user.name} {value.user.surnames}</span>
              </div>
            </div>
          })
        }
        <ErrorsComponent name='participants' errors={errors} />
      </div>

      <div className='File'>
        <p>{t('share-file.file')}</p>
        <div className='fileUpload'>
          <label htmlFor="file-upload" className='custom-file-upload'><i className="las la-file-upload"></i></label>
          <input type='file' id="file-upload" onChange={selectFile}
            accept={ALLOWED_MIMETYPES.join(',')}

          />
          <small className='originalFileName'>{originalFilename !== '' ? t('share-file.file-selected', {filename: originalFilename}) : t('share-file.no-file')}</small>
        </div>

        <ErrorsComponent name='file' errors={errors} />
        <ErrorsComponent name='size' errors={errors} />
        <ErrorsComponent name='type' errors={errors} />
      </div>


      {
        fileToSend &&
        <>
          <div className='File'>
            <p>{t('share-file.filename')}</p>
            <div className='Input'>
              <i className="las la-file-invoice"></i>
              <input type='text' value={filename} onChange={(e) => {
                setFilename(e.target.value);
              }} />
            </div>

            <ErrorsComponent name='filename' errors={errors} />

          </div>
        </>
      }
      <div className='File'>
        <button className='Submit' onClick={handleSendFile}>{t('share-file.accept')}</button>
      </div>
  </div>
}
