// Modules
import axios from 'axios';
import 'moment/locale/es';
import { useEffect, useRef, useState } from 'react';
import Datetime from 'react-datetime';

// Environment variables
import { environment } from '../../environment';

// Types
import { UserType } from '../../types/user.type';
import { PatientType } from '../directory/directory.types';
import { ErrorsType } from '../errors/errors.type';

// Components
import { DirectoryUpdateComponent } from '../directory/directory.update';
import { ErrorsComponent } from '../errors/errors.component';
import { LoaderComponent } from '../loader/loader.component';
import { MeetParticipantsComponent } from './schedule.select.participants';

// Styles
import './schedule.styles.scss';
import { MeetType } from './schedule.types';
import { useTranslation } from 'react-i18next';

/**
 * Schedule Update Component
 * @description Screen to update a schedule in the platform
 * @param properties
 * @returns {JSX.Element}
 */
export function ScheduleUpdateComponent(
    properties: {
        setScreen: (screen: string) => void
        user: UserType
        updateData: () => void
        deselect: () => void
        meet: MeetType
        subscreen?: string
    }
): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // errors in update request
    const [errors, setErrors] = useState<ErrorsType>({})

    // success in update request
    const [success, setSuccess] = useState<boolean>(false)

    // subscreen to show
    const [subscreen, setSubscreen] = useState<string | undefined>(properties.subscreen)

    // key to force re-render
    const [random, setRandom] = useState<string>('start')

    // loading of axios request
    const [loading, setLoading] = useState<boolean>(false)

    // list inner ref to scroll to top
    const listInnerRef = useRef<HTMLDivElement>(null);

    // patient to relation meet
    const [patient, setPatient] = useState<PatientType>()

    // data to update
    const [data, setData] = useState<any>(properties.meet)

    // get patient
    const getPatient = () => {

        // path of controller in backend
        const path = `${environment.api.host}/api/patient/${properties.meet.patient_id}`;

        // request axios
        axios.get(path, {
            headers: {
                Authorization: `Bearer ${properties.user.access_token}`
            }
        })
            .then((response) => {

                // set patient
                response?.data?.items?.[0] && setPatient(response.data.items[0])

            })
            .catch((error) => {
                console.log(error)
            })
    }
    useEffect(() => {

        // get patient in first render
        getPatient()

    }, [])

    const onSubmit = () => {

        // show loading
        setLoading(true)

        // clean errors
        setErrors({})

        // request axios
        axios.put(`${environment.api.host}/api/meet/${properties.meet.id}`, {
            finished_at: data.finished_at,
            started_at: data.started_at,
            subject: data.subject,
        }, {
            headers: {
                Authorization: `Bearer ${properties.user.access_token}`
            }
        }).then((response) => {

            // delay to hide loader
            setTimeout(() => {

                // hide loader
                setLoading(false)

                // show success
                setSuccess(true)

                // scroll to top
                listInnerRef.current?.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })

                // update data
                properties.updateData();

                // set random to re-render
                setRandom(Math.random().toString())

            }, 1000);
        })
            .catch((error) => {

                // delay to hide loader
                setTimeout(() => {

                    // hide loader
                    setLoading(false)

                    // scroll to top
                    setRandom(Math.random().toString())

                    // get errors
                    const errors = error?.response?.data?.errors;

                    // set errors
                    if (errors) setErrors(errors)

                    // default error
                    else setErrors({ general: ['Error de conexión'] })

                }, 1000);
            })
    }

    return <div className="ScheduleComponent Modal">
        <div className='Header'>
            <i
                className='las la-angle-left'
                onClick={() => { properties.deselect() }}
            />
            <b>
                {t('schedule.update.back')}
            </b>
        </div>
        {patient && <div className='Link ' onClick={() => setSubscreen('patient')}>
            <i className='las la-notes-medical'></i>
            {patient.name} {patient.surnames} <i className='las la-angle-right' />
        </div>}
        <div className='Link last' onClick={() => setSubscreen('participants')}>
            <i className='las la-user-friends'></i>
            {t('schedule.update.participants')} <i className='las la-angle-right' />
        </div>
        <div className='Form'
            ref={listInnerRef}
            key={random}
            style={{
                paddingTop: 10,
            }}>
            {
                success && <div className='Success'>
                    <i className="las la-check"></i>
                    <p>{t('schedule.update.success')}</p>
                </div>
            }
            <p>{t('schedule.update.subject')}*</p>
            <div className='Input'>
                <i className="las la-quote-left"></i>
                <input
                    type="text"
                    placeholder=""
                    onChange={(event) => {
                        setData({ ...data, subject: event.target.value })
                    }}
                    value={data.subject}
                />
            </div>
            <ErrorsComponent errors={errors} name="subject" />

            <p>{t('schedule.update.start')}*</p>
            <div className='Calendar'>

                <i className="las la-calendar"></i>
                <Datetime
                    locale="es"
                    initialValue={data.started_at}
                    onChange={(date) => {
                        setData({ ...data, started_at: date })
                    }}
                />
            </div>


            <ErrorsComponent errors={errors} name="started_at" />
            <p>{t('schedule.update.end')}*</p>

            <div className='Calendar'>

                <i className="las la-calendar"></i>
                <Datetime
                    locale="es"
                    initialValue={data.finished_at}
                    onChange={(date) => {
                        setData({ ...data, finished_at: date })
                    }}
                />
            </div>


            <ErrorsComponent errors={errors} name="finished_at" />
            <div className='Space' />
            <div className='Submit-position'>
                <div className='Submit' onClick={onSubmit} >
                    {t('schedule.update.submit')}
                </div>
            </div>
        </div>
        <LoaderComponent status={loading} />
        {
            subscreen === 'participants' && <MeetParticipantsComponent
                setSubscreen={setSubscreen}
                user={properties.user}
                meet={properties.meet}
                setScreen={properties.setScreen}
            />
        }
        {
          // NOTE(2024-07-04): Hide patient button for all users (Comitas requested)
          false && subscreen === 'patient' && patient && <DirectoryUpdateComponent
            patient={patient}
            user={properties.user}
            updateData={() => {
                getPatient()
                properties.updateData()
            }}
            setScreen={setSubscreen}
            onSuccesChange={() => {}}
          />
        }
    </div>

}
