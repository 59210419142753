// Modules
import React, { Component } from 'react';

/**
 * Audio Visualiser
 * @description Visualise audio data
 * @return {Component} AudioVisualiser
 */
export class AudioVisualiser extends Component {

  constructor(props) {

    super(props);

    // reference of canvas to draw the AudioVisualiser
    this.canvas = React.createRef();

  }

  componentDidUpdate() {

    this.draw();

  }

  draw() {

    // audio data
    const { audioData } = this.props;

    // get canvas from ref
    const canvas = this.canvas.current;

    // set canvas height and width
    const height = canvas.height;
    const width = canvas.width;

    // get context of canvas
    const context = canvas.getContext('2d');

    // reduce the width of audio wave
    let x = -30;
    const sliceWidth = (width * 1.4) / audioData.length;

    // set the style of audio wave
    context.lineWidth = 5;
    context.strokeStyle = 'white';
    context.clearRect(0, 0, width, height);
    context.beginPath();
    context.moveTo(0, height / 1);

    // draw the audio wave
    for (const item of audioData) {

      const y = (item / 255.0) * height;
      context.lineTo(x, y);
      x += sliceWidth;

    }
    context.lineTo(x, height * 4);
    context.stroke();

  }

  render() {

    return <canvas width="500" style={{ zIndex: 10000000, }} height="600" ref={this.canvas} />;

  }
}

