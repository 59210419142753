import axios, { AxiosResponse } from "axios";
import { UserType } from "../types/user.type";
import { environment } from "../environment";

export class UserHelper {

  /**
   * Update the last login of the user
   *
   * @param user User object to update the last login
   * @returns {Promise<boolean>} True if the last login was updated successfully
   */
  static async updateUserLastLogin(
    user: UserType | undefined
  ): Promise<boolean> {
    if (!user) return false;

    const response: AxiosResponse = await axios.patch(
      `${environment.api.host}/api/user/${user.id}/last-login`,
      {},
      {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      }
    );

    return response.status === 200 || response.status === 201;
  }
}
