// Modules
import { useEffect, useState } from 'react';

/**
 * Internet Speed Meter
 * @param {*} param0 
 * @returns 
 */
export const ReactInternetSpeedMeter = ({
  pingInterval = 2000,
  threshold = 7,
  imageUrl,
  downloadSize,
  callbackFunctionOnError = () => console.log('error in downloading image'),
}) => {

  // States of connection
  const [isNetworkDown, setisNetworkDown] = useState(false);
  const [speedCurrent, setSpeedCurrent] = useState(0);

  // Events of connection
  window.addEventListener('offline', () => setisNetworkDown(true));
  window.addEventListener('online', () => setisNetworkDown(false));

  let intervalFun;
  useEffect(() => {

    const intervalFun = setInterval(() => {
      MeasureConnectionSpeed();
    }, pingInterval);
    return () => {
      window.clearInterval(intervalFun);
    }

  }, []);
  const MeasureConnectionSpeed = () => {

    // start time of image download
    let startTime, endTime;

    // create a new image object
    let download = new Image();
    startTime = new Date().getTime();

    // add a unique cache key to bust the cache and trigger the image load
    let cacheBuster = '?nnn=' + startTime;

    // set the image source
    download.src = imageUrl + cacheBuster;

    download.onload = function (d) {

      endTime = new Date().getTime();
      showResults(startTime, endTime);

    };

    download.onerror = function (err, msg) {

      callbackFunctionOnError(err);
      window.clearInterval(intervalFun);

    };

  };

  const showResults = (startTime, endTime) => {

    const duration = (endTime - startTime) / 1000;
    const bitsLoaded = downloadSize * 8;
    const speedBps = (bitsLoaded / duration).toFixed(2);
    const speedKbps = (speedBps / 1024).toFixed(2);
    const speedMbps = (speedKbps / 1024).toFixed(2);

    setNetworStatus(speedBps, speedKbps, speedMbps);

  };

  const setNetworStatus = (speedBps, speedKbps, speedMbps) => {

    if (speedMbps < threshold) setisNetworkDown(false);
    else setisNetworkDown(true);

    setSpeedCurrent(speedMbps);

  };

  if (isNetworkDown) {

    return (
      <div className='Internet'>

        <b>0</b> mb/s
      </div>
    );

  }
  return (
    <div className='Internet'>
      <b>{speedCurrent}</b> mb/s
    </div>
  );

};
