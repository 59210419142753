// Modules
import axios from 'axios';
import 'moment/locale/es';
import { useRef, useState } from 'react';
import moment from 'moment';

// Environment variables
import { environment } from '../../environment';

// Types
import { UserType } from '../../types/user.type';
import { ErrorsType } from '../errors/errors.type';

// Components
import { AccordionComponent } from '../accordion/accordion.component';
import { ErrorsComponent } from '../errors/errors.component';
import { LoaderComponent } from '../loader/loader.component';

// Styles
import './directory.styles.scss';
import { useTranslation } from 'react-i18next';

/**
 * Directory Update Component
 * @description Screen to update a patient from the platform
 * @returns {JSX.Element}
 */
export function DirectoryUpdateComponent(properties: {
    setScreen: (screen: string) => void
    user: UserType
    updateData: () => void
    onSuccesChange: (type: string) => void
    patient: any
}): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // errors in update request
    const [errors, setErrors] = useState<ErrorsType>({})

    // refresh key to update component
    const [random, setRandom] = useState<string>('start')

    // loading of axios request
    const [loading, setLoading] = useState<boolean>(false)

    // list inner ref to scroll to top
    const listInnerRef = useRef<HTMLDivElement>(null);

    // data of patient
    const [data, setData] = useState<any>(properties.patient)

    const onSubmit = () => {

        // show loading
        setLoading(true)

        // clean errors
        setErrors({})

        // update patient
        axios.put(`${environment.api.host}/api/patient/${properties.patient.id}`, {
            ...data,
            height: data.height ? Number(data.height) : 0,
            weight: data.weight ? Number(data.weight) : 0,
        }, {
            headers: {
                Authorization: `Bearer ${properties.user.access_token}`
            }
        }).then((response) => {

            // delay to show success
            setTimeout(() => {

                // hide loading
                setLoading(false)

                // scroll to top
                listInnerRef.current?.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })

                // update data
                properties.updateData();

                // reset render
                setRandom(Math.random().toString())

                // set screen to list
                properties.setScreen('list');

                // Send success message to parent component
                properties.onSuccesChange('update');

            }, 300);
        })
            .catch((error) => {

                // delay to hide loading
                setTimeout(() => {

                    // hide loading
                    setLoading(false)

                    // get errors
                    const errors = error?.response?.data?.errors;

                    // set errors
                    if (errors) setErrors(errors)

                    // define general error
                    else setErrors({ general: ['Error de conexión'] })

                }, 300);
            })
    }

    return <div className="DirectoryComponent Modal">

        {/* Header Update Patient */}
        <div className='Header'>
            <b>{t('directory.update.title')}</b>
            <i className='las la-times' onClick={() => { properties.setScreen('list') }}></i>
        </div>

        {/* Form Update Patient */}
        <div
            className='Form'
            ref={listInnerRef}
            key={random}
            style={{
                paddingTop: 10,
            }}
        >
            <div className='Pair'>
                <p>{t('directory.update.name')}*</p>
                <p>{t('directory.update.surname')}*</p>
            </div>
            <div className='Pair'>
                <div className='Input'>

                    <input
                        type="text"
                        onChange={(event) => {
                            setData({ ...data, name: event.target.value })
                        }}
                        value={data.name}
                    />
                    <ErrorsComponent errors={errors} name="name" />
                </div>
                <div className='Input'>

                    <input
                        type="text"
                        onChange={(event) => {
                            setData({ ...data, surname: event.target.value })
                        }}
                        value={data.surname}
                    />
                    <ErrorsComponent errors={errors} name="surname" />
                </div>
            </div>

            <p>{t('directory.update.document')}*</p>
            <div className='Input'>

                <i className="las la-id-card"></i>
                <input
                    type="text"
                    onChange={(event) => {
                        setData({ ...data, identification: event.target.value })
                    }}
                    value={data.identification}
                />

            </div>
            <ErrorsComponent errors={errors} name="identification" />

            <div className='Space'></div>

            <AccordionComponent title={t('directory.update.information')}>
                <p>{t('directory.update.born')}</p>
                <div className='Input'>

                    <i className="las la-birthday-cake"></i>
                    <input
                        type="date"
                        onChange={(event) => {
                            setData({ ...data, born: event.target.value })
                        }}
                        value={data.born}
                    />

                </div>
                <ErrorsComponent errors={errors} name="born" />
                <p>{t('directory.update.occupation')}</p>
                <div className='Input'>

                    <input
                        type="text"
                        value={data.occupation}
                        onChange={(event) => {
                            setData({ ...data, occupation: event.target.value })
                        }}
                    />

                </div>
                <ErrorsComponent errors={errors} name="work" />
                <p>{t('directory.update.observations')}</p>
                <div className='Input'>
                    <textarea
                        value={data.description}
                        onChange={(event) => {
                            setData({ ...data, description: event.target.value })
                        }}
                    />

                </div>
                <ErrorsComponent errors={errors} name="observations" />
            </AccordionComponent>
            <AccordionComponent title={t('directory.update.history')}>
                <div className='History'>

                    {
                        data.history?.map?.((item: any, index: number) => {

                            return <div
                                className='HistoryItem'
                                key={index}
                            >

                                <p>{t('directory.update.history.date')}</p>
                                <div className='Input'>                                    
                                    <input
                                        value={moment(item?.date).format('YYYY-MM-DD HH:mm').toString()}
                                        disabled={true}
                                        readOnly={true}                                        
                                    />
                                </div>

                                <p>{t('directory.update.history.type')}</p>
                                <div className='Input'>

                                    <select
                                        value={item?.type}
                                        onChange={(event) => {

                                            let history = data.history;
                                            history[index].type = event.target.value;
                                            setData({ ...data, history: history })

                                        }}
                                    >
                                        <option value="1">{t('directory.update.history.type.1')}</option>
                                        <option value="2">{t('directory.update.history.type.2')}</option>
                                        <option value="3">{t('directory.update.history.type.3')}</option>
                                        <option value="4">{t('directory.update.history.type.4')}</option>
                                    </select>

                                </div>

                                <p>{t('directory.update.observations')}</p>
                                <div className='Input'>
                                    <textarea

                                        value={item?.observation}
                                        onChange={(event) => {

                                            let history = data.history;
                                            history[index].observation = event.target.value;
                                            setData({ ...data, history: history })
                                        }}
                                    />

                                </div>
                                <div className='Submit delete' onClick={() => {
                                    let history = data.history;
                                    history.splice(index, 1);
                                    setData({ ...data, history: history })
                                }} >
                                    {
                                        t('directory.update.history.delete')
                                    }
                                </div>
                            </div>
                        })
                    }
                    <div
                        style={{
                            marginLeft: 20,
                            marginRight: 20,
                            maxWidth: 360,
                            width: '100%',                            
                            opacity: 1,
                        }} className='Submit' onClick={() => {
                            setData({
                                ...data,
                                history: [...data.history, { 
                                    observation: '', 
                                    type: 1,
                                    date: moment(), 
                                    description: '',
                                }]
                            })
                        }}>
                        {
                            t('directory.update.history.add')
                        }
                    </div>
                </div>
            </AccordionComponent>

            <AccordionComponent title={t('directory.update.address')}>
                <p>{t('directory.update.street')}</p>
                <div className='Input'>

                    <input type="text"

                        value={data.address_street}
                        onChange={(event) => {
                            setData({ ...data, address_street: event.target.value })
                        }}

                    />

                </div>
                <ErrorsComponent errors={errors} name="address_street" />
                <p>{t('directory.update.city')}</p>
                <div className='Input'>

                    <input type="text"

                        value={data.address_city}
                        onChange={(event) => {
                            setData({ ...data, address_city: event.target.value })
                        }}

                    />

                </div>
                <ErrorsComponent errors={errors} name="address_city" />
                <p>{t('directory.update.zip')}</p>
                <div className='Input'>

                    <input type="text"

                        value={data.address_zip}
                        onChange={(event) => {
                            setData({ ...data, address_zip: event.target.value })
                        }}

                    />

                </div>
                <ErrorsComponent errors={errors} name="address_zip" />
                <p>{t('directory.update.country')}</p>
                <div className='Input'>

                    <input type="text"

                        value={data.address_country}
                        onChange={(event) => {
                            setData({ ...data, address_country: event.target.value })
                        }}

                    />

                </div>
                <ErrorsComponent errors={errors} name="address_country" />

            </AccordionComponent>
            <AccordionComponent title={t('directory.update.contact')}>
                <p>{t('directory.update.email')}</p>

                <div className='Input'>

                    <i className="las la-envelope"></i>
                    <input
                        type="text"
                        placeholder="Email"
                        onChange={(event) => {
                            setData({ ...data, email: event.target.value })
                        }}
                        value={data.email}
                    />

                </div>
                <ErrorsComponent errors={errors} name="email" />
                <p>{t('directory.update.phone')}</p>
                <div className='Input'>

                    <i className="las la-phone"></i>
                    <input type="text"

                        value={data.phone}
                        onChange={(event) => {
                            setData({ ...data, phone: event.target.value })
                        }}

                    />

                </div>
                <ErrorsComponent errors={errors} name="phone" />
            </AccordionComponent>
            <AccordionComponent title={t('directory.update.phisic')}>
                <p>{t('directory.update.genre')}</p>
                <div className='Input'>

                    <i className="las la-male"></i>
                    <select
                        onChange={(event) => {
                            setData({ ...data, genre: event.target.value })
                        }}
                    >
                        <option value="1">{t('directory.update.genre.1')}</option>
                        <option value="2">{t('directory.update.genre.2')}</option>
                        <option value="3">{t('directory.update.genre.3')}</option>
                    </select>

                </div>
                <ErrorsComponent errors={errors} name="genre" />
                <p>{t('directory.update.height')}</p>
                <div className='Input'>

                    <i className="las la-ruler-vertical"></i>
                    <input type="text"
                        placeholder={`${t('directory.update.height.placeholder')}`}
                        onChange={(event: any) => {
                            setData({ ...data, height: event.target.value })
                        }}
                        value={data.height}
                    />

                </div>
                <ErrorsComponent errors={errors} name="height" />
                <p>{t('directory.update.size')}</p>
                <div className='Input'>

                    <i className="las la-weight"></i>
                    <input
                        type="text"
                        placeholder={`${t('directory.update.size.placeholder')}`}
                        onChange={(event: any) => {
                            setData({ ...data, weight: event.target.value })
                        }}
                        value={data.weight}
                    />

                </div>
                <ErrorsComponent errors={errors} name="weight" />
            </AccordionComponent>

            <div className='AccordionLine'></div>
            <div className='Submit-position'>
                <div className='Submit' onClick={onSubmit} >
                    {t('directory.update.submit')}
                </div>
            </div>

        </div>

        <LoaderComponent status={loading} />

    </div>


}