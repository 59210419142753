// Components
import { useTranslation } from 'react-i18next';
import { ReactInternetSpeedMeter } from '../internet';

// Styles
import './error.styles.scss';

/**
 * Error Component
 * @description Component to render error
 * @returns {JSX.Element}
 */
export function ErrorComponent(): JSX.Element {

    // translation function
    const { t } = useTranslation();

    return <div className="ErrorComponent">
        <i className='las la-exclamation-triangle'></i>
        <div className='title'>
            {t('error.title')}
        </div>
        <div className='description'>
            {t('error.description')}
        </div>
        <ReactInternetSpeedMeter
            txtSubHeading="Internet is too slow"
            outputType="alert"
            customClassName={null}
            txtMainHeading="Opps..."
            pingInterval={1000} // Milliseconds 
            thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte" 
            threshold={100}
            imageUrl="https://comitas.es/wp-content/uploads/2023/04/Domus-VI-Jornada.png"
            downloadSize="115343"  // Bytes
            callbackFunctionOnNetworkDown={(speed: any) => {

                console.log(`Internet speed is down ${speed}`);

            }}
            callbackFunctionOnNetworkTest={(speed: any) => console.log('Baja')}
        />
        <div
            onClick={() => window.location.reload()}
            className='Submit'>
            {
                t('error.button')
            }
        </div>
    </div>

}