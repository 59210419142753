// Modules
import { useEffect } from "react";

// Constants
import { ErrorsConstants } from "./errors.constants";

// Properties
import { ErrorsProperties } from "./errors.properties";

// Styles
import "./errors.styles.scss";
import { TranslateError } from "../../translations";

/**
 * Errors Component
 * @description Component for rendering a series of typed errors primarily for
 * the form or the table. It can be used to render any type of error.
 * @param {ErrorsProperties} properties
 * @returns {JSX.Element}
 */
export function ErrorsComponent(properties: ErrorsProperties): JSX.Element {

    /**
     * Scroll to component
     * @description The idea is that when an error is rendered, it will scroll to the component
     */
    useEffect(() => {

        setTimeout(() => {

            // we use pure javascript to scroll for performance
            const element = document.querySelectorAll(`.${ErrorsConstants.CLASSNAME}`)
            if (element && element[0]) {
                // the scroll margin is defined in the css
                element[0].scrollIntoView({ behavior: 'smooth' })
            }
        }, 300)


    }, [properties?.errors])

    return <>
        {
            properties?.errors && Object.keys(properties?.errors || {})?.map((name, index) => {

                return (!properties?.name || name === properties?.name) &&
                    <ul className={ErrorsConstants.CLASSNAME} key={index}>
                        {
                            properties?.errors?.[name]?.map((error, index) => {
                                return <li key={index}>{TranslateError(error)}</li>
                            })
                        }
                    </ul>
            })
        }
    </>

}