// Modules
import { createRef, useEffect, useState } from "react"

// Styles
import './accordion.styles.scss'
import { AccordionProperties } from "./accordion.properties";

/**
 * Accordion Component
 * @param {AccordionProperties} properties 
 * @returns {JSX.Element}
 */
export function AccordionComponent(properties: AccordionProperties): JSX.Element {

    // status of the accordion
    const [open, setOpen] = useState<boolean>(false)

    // reference to scroll to the accordion
    const ref = createRef<HTMLDivElement>();

    // animation to scroll to the accordion
    useEffect(() => {

        setTimeout(() => {
            open && ref.current && ref.current.scrollIntoView({ behavior: 'smooth' })
        }, 300)

    }, [open])

    return <div
        ref={ref}
        className='AccordionComponent'
    >
        <div
            className='title'
            onClick={() => setOpen(!open)}
        >
            <i className={`las la-angle-${open ? 'up' : 'down'}`} />
            {properties.title}
        </div>

        {
            open && <div className='content'>
                {properties.children}
            </div>
        }
    </div>

}