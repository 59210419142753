// Modules
import axios from 'axios';
import { useState } from 'react';

// Environment variables
import { environment } from '../../environment';

// Types
import { UserType } from '../../types/user.type';
import { ErrorsType } from '../errors/errors.type';

// Components
import { ErrorsComponent } from '../errors/errors.component';
import { LoaderComponent } from '../loader/loader.component';

// Styles
import './directory.styles.scss';
import { useTranslation } from 'react-i18next';

/**
 * Directory Delete Component
 * @description Screen to remove a patient from the platform
 * @returns {JSX.Element}
 */
export function DirectoryDeleteComponent(
    properties: {
        setScreen: (screen: string) => void
        user: UserType
        updateData: () => void
        onSuccesChange: (type: string) => void
        id: string
    }
): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // errors in delete request
    const [errors, setErrors] = useState<ErrorsType>({})

    // success in delete request
    const [success, setSuccess] = useState<boolean>(false)

    // loading of axios request
    const [loading, setLoading] = useState<boolean>(false)

    const onSubmit = () => {

        // show loading
        setLoading(true)

        // clean errors
        setErrors({})

        // delete patient
        axios.delete(`${environment.api.host}/api/patient/${properties.id}`, {
            headers: {
                Authorization: `Bearer ${properties.user.access_token}`
            }
        }).then((response) => {

            // delay to show success
            setTimeout(() => {

                setLoading(false)
                setSuccess(true)
                properties.updateData();

                // set screen to list
                properties.setScreen('list');

                // Send success to parent component
                properties.onSuccesChange('delete')                

            }, 1000);

        })
            .catch((error) => {

                setTimeout(() => {

                    // hide loading
                    setLoading(false)

                    // get errors
                    const errors = error?.response?.data?.errors;

                    // set errors
                    if (errors) setErrors(errors)

                    // define general error
                    else setErrors({ general: ['Error de conexión'] })

                }, 1000);

            })
    }

    return <div
        className="DirectoryComponent Modal"
    >
        <div className='Header'>
            <b>{t('directory.delete.title')}</b>
            <i className='las la-times' onClick={() => { properties.setScreen('list') }} />
        </div>

        <div
            className='Form'
            style={{
                paddingTop: 10,
            }}>
            <ErrorsComponent errors={errors} name="general" />
            <div className='Information'>
                {t('directory.delete.information')}
            </div>
            {
                !success && <div className='Submit-position'>
                    <div className='Submit' onClick={onSubmit} >
                        {t('directory.delete.submit')}
                    </div>
                </div>
            }
        </div>
        <LoaderComponent status={loading} />
    </div>

}