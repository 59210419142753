import { useEffect, useState } from "react";

export function CountdownComponent({ time, onFinish }: { time: number, onFinish: () => void }): JSX.Element {
    const [countdown, setCountdown] = useState<number>(time);

    useEffect(() => {
      const interval = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown <= 1) {
            clearInterval(interval);
            onFinish();
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }, []);

    return <span style={
        {
            /* Color opacity */
            color: 'rgba(255, 255, 255, 0.8)',
            /* Padding left */
            paddingLeft: 10,
        }
    }>
        {countdown > 0 && countdown}
    </span>
}
