import React from "react";
import "./microsoft.button.scss";

interface MicrosoftButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
}

const MicrosoftButton = (props: MicrosoftButtonProps) => {
  return (
    <button
      className="microsoft-button"
      style={{ width: "80%", ...props.style }}
      onClick={props.onClick}
    >
      <div className="microsoft-button-content-wrapper">
        <div className="microsoft-button-icon">
          <svg
            viewBox="0 0 46 46"
            width="20"
            height="20"
            style={{ display: "block" }}
          >
            <path d="M23 2H2v21h21V2z" fill="#f25022" />
            <path d="M44 2H23v21h21V2z" fill="#7fba00" />
            <path d="M23 23H2v21h21V23z" fill="#00a4ef" />
            <path d="M44 23H23v21h21V23z" fill="#ffb900" />
          </svg>
        </div>
        <span className="microsoft-button-contents">
          Sign in with Microsoft
        </span>
      </div>
    </button>
  );
};

export default MicrosoftButton;
