const dev = (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development');

const domain = dev ? 'https://sippar4.enovait.biz' : 'https://telemedicinacomitas.com';
const apiDomain = dev ? 'https://sippar4.enovait.biz' : 'https://telemedicinacomitas.com';

export const environment = {
    brickter: {
        name: 'comitas',
    },
    production: false,
    debug: false,
    cheeky: {
        host: domain,
    },
    dish: {
        host: domain,
        iceServers: [

            { urls: "stun:telemedicinacomitas.com:3478", },
            { urls: "turn:telemedicinacomitas.com:3478?transport=udp", username: 'enovait', credential: 'password' },

        ]
    },
    api: {
        // DESPLIEGUE COMITAS
        host: apiDomain,
        // host: 'https://sippar4.ddns.net',
    },
};
