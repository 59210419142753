// Instances
import { DishBackgroundVideo } from "./background.instance";
import { toast } from "react-toastify";

// Tanslations
import i18n from 'i18next';

// Types
import { ConnectDeviceOptions } from "../dish.types";

/**
 * Device Filter Type
 * @type DeviceFilterType
 */
export type DeviceFilterType = {
    audio?: boolean;
    video?: boolean | {
        pan: true, tilt: true, zoom: true
    };
}

/**
 * Device Instance
 * @class DeviceInstance
 */
export class DeviceInstance {

    dishBackgroundVideoInstance: DishBackgroundVideo | undefined;

    constructor() {
      console.log(`[DeviceInstance] constructor`)
    }

    async stopBackground() {
        this.dishBackgroundVideoInstance?.stop?.();
    }

    async replaceBackground(stream: MediaStream, image: string) {

        return new Promise<MediaStream>((resolve, reject) => {

            // create image html
            const background = document.createElement('img');

            background.src = image;
            // set in body
            document.body.appendChild(background);
            // position fixed 300x300 zindex:1000
            background.style.position = 'fixed';
            background.style.top = '0';
            background.style.left = '0';
            background.style.zIndex = '-1000';
            // pointer events none
            background.style.pointerEvents = 'none';
            // hide
            background.style.opacity = '0';
            background.onload = async () => {

                setTimeout(async () => {
                    const segmentation = new DishBackgroundVideo({ stream: stream, background });
                    this.dishBackgroundVideoInstance = segmentation;
                    segmentation.run();
                    const streamResult = await segmentation.getStream();

                    stream.addEventListener('inactive', () => {

                        segmentation.stop();

                    });
                    stream.addEventListener('ended', () => {

                        segmentation.stop();
                    });
                    return resolve(streamResult);
                }, 1000);

            }


        });

    }
    async getFilterStream(options: MediaStreamConstraints) {

        try {

            const localStream = await navigator.mediaDevices.getUserMedia(options);
            return localStream;

        }
        catch (e) {

            console.error('ERROR -> getDefaultDevice', e);

        }

    }


    /**
     * Retrieves the default media device (audio and video) stream.
     *
     * This function attempts to get the user's media devices with specific constraints
     * for audio and video. If a filter is provided, it checks if any of the audio or video
     * device labels match the filter. If a match is found, it logs an error and shows a warning
     * message indicating that the device is not valid.
     *
     * @param {string[]} [filter] - An optional array of device labels to filter out. If any of the
     * device labels match the filter, the function will return `undefined` and show a warning message.
     *
     * @returns {Promise<MediaStream | undefined>} - A promise that resolves to the media stream if successful,
     * or `undefined` if an error occurs or a filtered device is found.
     */
    async getDefaultDevice(filter?: string[]): Promise<MediaStream | undefined> {
      try {
        const localStream = await navigator.mediaDevices.getUserMedia({
            audio: {
                echoCancellation: true,
                noiseSuppression: true,
                frameRate: 13,
                sampleRate: 44100,
                // @ts-ignore
                suppressLocalAudioPlayback: true,
            },
            video: {
                frameRate: 15,
                echoCancellation: true,
                noiseSuppression: true,
                sampleRate: 44100,
                // @ts-ignore
                zoom: true,
                pan: true,
                tilt: true,
            }
        });

        const audioTracks = localStream.getAudioTracks();
        const videoTracks = localStream.getVideoTracks();

        // Comprobar si alguno de los labels de audio o video coincide con los filtros
        if (filter) {
          console.log('DEBUG -> getDefaultDevice', 'Filter', filter);
          
          const audioFilter = audioTracks.find((audioTrack) => filter.includes(audioTrack.label));
          const videoFilter = videoTracks.find((videoTrack) => filter.includes(videoTrack.label));

          // Si encontramos un filtro, es que se usa un dispositivo que no se debe usar
          // Por lo que se debe mostrar un mensaje de error para informar de la
          // incompatibilidad del dispositivo
          if (audioFilter) {
            console.error('ERROR -> getDefaultDevice', 'Audio device not available', audioFilter.label);
            toast.warn(i18n.t('warn.device.audio.not.valid'));
            return undefined;
          }

          if (videoFilter) {
            console.error('ERROR -> getDefaultDevice', 'Video device not available', videoFilter.label);
            toast.warn(i18n.t('warn.device.video.not.valid'));
            return undefined;
          }
        }

        return localStream;
      }
      catch (e) {
        console.error('ERROR -> getDefaultDevice', e);
        toast.info(i18n.t('info.device.not.available'));
      }

      return undefined;
    }

    async getDevices(filter?: DeviceFilterType) {

        const devices = await navigator.mediaDevices.enumerateDevices();
        const filteredDevices = devices.filter((device) => {

            if (filter) {

                if (filter.audio && device.kind === 'audioinput') {

                    return true;

                }
                if (filter.video && device.kind === 'videoinput') {

                    return true;

                }

            }
            return false;

        });
        return filteredDevices;

    }

    async getDeviceMedia(devices: { videoId: string } | { audioId: string } | { videoId: string, audioId: string }, options?: ConnectDeviceOptions) {
        let deviceAudio: MediaDeviceInfo | undefined = undefined
        let deviceVideo: MediaDeviceInfo | undefined = undefined
        if ('videoId' in devices) {

            // find device of video or audio
            const videoDevices = await this.getDevices({ video: true, });
            deviceVideo = videoDevices.find((device) => device.deviceId === devices.videoId);
            if (!deviceVideo) {

                throw new Error('Device not found');

            }

        }
        if ('audioId' in devices) {

            // find device of video or audio
            const audioDevices = await this.getDevices({ audio: true });
            deviceAudio = audioDevices.find((device) => device.deviceId === devices.audioId);
            if (!deviceAudio) {

                throw new Error('Device not found');

            }

        }

        // if is audio
        if ('audioId' in devices && 'videoId' in devices) {

            const localStream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: devices.audioId,
                    noiseSuppression: options?.noise,
                    echoCancellation: options?.echo,
                    frameRate: 10
                },
                video: {
                    deviceId: devices.videoId ? { exact: devices.videoId } : undefined,
                    frameRate: 13,
                    noiseSuppression: options?.noise,
                    echoCancellation: options?.echo,
                    width: 300,
                    // @ts-ignore
                    pan: true,
                    tilt: true,
                    zoom: true

                },
            });

            console.log('localStream', localStream.getAudioTracks()[0]);
            console.log('localStream', localStream.getVideoTracks()[0]);

            if (options?.background) {
                const streamBackground = await this.replaceBackground(localStream, options?.background);
                return streamBackground;

            }
            else return localStream;

        }
        else if (deviceAudio && 'audioId' in devices) {

            const localStream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: devices.audioId,
                    noiseSuppression: options?.noise,
                    echoCancellation: options?.echo,
                },
                video: false,
            });

            console.log('localStream', localStream.getAudioTracks()[0]);
            console.log('localStream', localStream.getVideoTracks()[0]);

            return localStream;

        }
        if (deviceVideo && deviceVideo.kind === 'videoinput' && 'videoId' in devices && deviceAudio === undefined) {

            try {

                const localStream = await navigator.mediaDevices.getUserMedia({
                    audio: false,
                    video: {
                        deviceId: devices.videoId,
                        frameRate: 30,
                        echoCancellation: options?.echo,
                        noiseSuppression: options?.noise,
                        // @ts-ignore

                        pan: true,
                        tilt: true,
                        zoom: true

                    },
                });

                if (options?.background) {

                    return await this.replaceBackground(localStream, options?.background);

                }
                else return localStream;

            }
            catch (e) {

                console.log(e)

            }

        }

    }

    async getScreenMedias() {

        const stream = await navigator.mediaDevices.getDisplayMedia({
            video: true,
            audio: true,
        });

        return stream;

    }

    /**
     * Asynchronously requests permissions for audio and video media devices.
     *
     * @returns {Promise<{ audio: boolean, video: boolean, media: boolean }>}
     * A promise that resolves to an object indicating the permission status for audio, video, and media.
     *
     * @example
     * const permissions = await DeviceInstance.getPermissions();
     * console.log(permissions); // { audio: false, video: false, media: false }
     */
    static async getPermissions(): Promise<{ audio: boolean, video: boolean, media: boolean }> {

      const permissions = {
          audio: false,
          video: false,
          media: false,
      };

      // @ts-ignore
      await navigator.getUserMedia({ video: { pan: true, tilt: true, zoom: true } }, function (stream) {

        stream.getTracks().forEach((x: any) => x.stop());

      }, (err: any) => console.log(err));

      // @ts-ignore
      await navigator.getUserMedia({ audio: true }, function (stream) {

        stream.getTracks().forEach((x: any) => x.stop());

      }, (err: any) => console.log(err));

      return permissions;

    }

    log(message: any, data?: any) {

    }

    async getAudioOutput() {

        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioOutput = devices.filter((device) => device.kind === 'audiooutput');
        return audioOutput;

    }

    async getAudioInput() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioInput = devices.filter((device) => device.kind === 'audioinput');
      return audioInput;
    }

    async getVideoInput() {

        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoInput = devices.filter((device) => device.kind === 'videoinput');
        return videoInput;

    }

    async changeAudioOutput(deviceId: string) {
      const audioTag = document.createElement('audio');
      document.body.appendChild(audioTag);

      if (!audioTag.setSinkId) {
        console.error('setSinkId is not supported in this browser.');
        document.body.removeChild(audioTag);
        return;
      }

      try {
        console.log('Attempting to set audio output to device:', deviceId);
        await audioTag.setSinkId(deviceId);
        console.log('Audio output is now set to', deviceId);
      } catch (e) {
        console.error('Error in changeAudioOutput', e);
      } finally {
        document.body.removeChild(audioTag);
      }
    }
}
