// Modules
import Moment from 'react-moment'

// Types
import { ChatMessageType } from '../../chat.types'

// Styles
import './message.styles.scss'

/**
 * Message Component
 * @description Component to show a message
 * @param {ChatMessageType} message
 * @returns {JSX.Element}
 */
export function MessageComponent({ message }: { message: ChatMessageType }) {

    return <div className={`MessageComponent ${message.type}`} >
        <div className='details'><div className='author'>{message.name}</div></div>
        <div className='message'>{message.message}</div>
        <div className='date'><Moment fromNow >{message.date}</Moment></div>
    </div>

}