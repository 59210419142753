import axios, { AxiosResponse } from "axios";
import { UserType } from "../types/user.type";
import { environment } from "../environment";
import {
  UserSettingsButtonType,
  UserSettingsType,
} from "../types/user_settings.type";

/**
 * Helper class for user settings related operations
 */
export class UserSettingsHelper {
  /**
   * Get the user settings for the given user uuid
   *
   * @param user User object or user uuid
   * @returns {Promise<UserSettingsType | undefined>} User settings object
   */
  static async getUserSettings(
    user: UserType|undefined
  ): Promise<UserSettingsType | undefined> {
    if (!user) return undefined;
    
    const endpoint = `${environment.api.host}/api/user/${user.id}/settings`;

    const response: AxiosResponse = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    });

    return response.data;
  }

  /**
   * Check if a button is visible or not by the name of the button
   * in the `user_setting` object received from the server
   *
   * By default all buttons are visible so if the `user_setting` is not defined
   * or the button is not found in the array of buttons then this function
   * return `true`
   * @param userSettings The user settings object to check
   * @param buttonName The name of the button to check
   * @returns {boolean} True or false depending on the visibility of the button
   */
  static checkUserSettingsButton(
    userSettings: UserSettingsType | undefined,
    buttonName: string
  ): boolean {
    if (!userSettings) return true;

    const button = userSettings?.buttons?.find(
      (button) => button.name === buttonName
    );

    if (!button) return true;

    return button.visible;
  }

  /**
   * Get the button object from the `user_settings` object
   * by the name of the button
   *
   * @param userSettings Object of type `UserSettingsType`
   * @param buttonName Name of the button to get
   *
   * @returns {UserSettingsButtonType | undefined} Button object
   */
  static getUserSettingsButton(
    userSettings: UserSettingsType | undefined,
    buttonName: string
  ): UserSettingsButtonType | undefined {
    if (!userSettings) return undefined;

    const button = userSettings?.buttons?.find(
      (button) => button.name === buttonName
    );

    return button;
  }
}
