import { useState, useEffect, useMemo } from 'react';

const useIdleTimer = (clientIdleTime: string, roomId?: string) => {
  const [idleTime, setIdleTime] = useState<number>(0);
  const [isUserIdle, setIsUserIdle] = useState<boolean>(false);

  const getMaxIdleTime = useMemo(() => {
    const match = clientIdleTime.match(/(\d+)([smhd])/);

    switch (match?.[2]) {
      case 's':
        return parseInt(match[1]) * 1000;
      case 'm':
        return parseInt(match[1]) * 60 * 1000;
      case 'h':
        return parseInt(match[1]) * 60 * 60 * 1000;
      case 'd':
        return parseInt(match[1]) * 24 * 60 * 60 * 1000;
      default:
        return 365 * 24 * 60 * 60 * 1000;
    }
  }, [clientIdleTime]);

  useEffect(() => {
    const handleActivity = () => {
      setIdleTime(0);
      setIsUserIdle(false);
    };

    const interval = setInterval(() => {
      setIdleTime((prevIdleTime) => {
        if (!roomId && (prevIdleTime >= getMaxIdleTime)) {
          setIsUserIdle(true);
          clearInterval(interval);

          localStorage.removeItem('user');
          localStorage.removeItem('user_settings');

          window.removeEventListener('load', handleActivity);
          window.removeEventListener('mousemove', handleActivity);
          window.removeEventListener('keydown', handleActivity);
          window.removeEventListener('scroll', handleActivity);
        }
        return prevIdleTime + 1000;
      });
    }, 1000);

    window.addEventListener('load', handleActivity);
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('scroll', handleActivity);

    return () => {
      clearInterval(interval);
      window.removeEventListener('load', handleActivity);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('scroll', handleActivity);
    };
  }, [getMaxIdleTime, roomId]);

  return isUserIdle;
};

export default useIdleTimer;
