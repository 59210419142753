// Modules
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDebounce } from 'use-debounce';

// Environment variables
import { environment } from '../../environment';

// Properties
import { DirectoryProperties } from './directory.properties';

// Types
import { PatientType } from './directory.types';

// Components
import { DirectoryInsertComponent } from './directory.insert';
import { LoaderComponent } from '../loader/loader.component';
import { DirectoryDeleteComponent } from './directory.delete';
import { DirectoryUpdateComponent } from './directory.update';

// Styles
import './directory.styles.scss';
import { useTranslation } from 'react-i18next';

/**
 * Directory Component
 * @description Component to manage the application's patients
 * @param {DirectoryProperties} properties
 * @returns {JSX.Element}
 */
export function DirectoryComponent(properties: DirectoryProperties): JSX.Element {

    const userSettings = properties.user.user_setting;

    // translation function
    const { t } = useTranslation();

    // patient selected to update or delete
    const [patientSelected, setPatientSelected] = useState<any>();

    // list of patients
    const [patients, setPatients] = useState<PatientType[]>([]);

    // screen to show
    const [screen, setScreen] = useState<string>('list');

    // search text
    const [search, setSearch] = useState<string>('');

    // loading
    const [loading, setLoading] = useState<boolean>(false);

    // search text debounced
    const [searchText] = useDebounce(search, 1000);

    // success in dinamic actions (insert, update, delete)
    const [success, setSuccess] = useState<boolean>(false)
    const [succesType, setSuccessType] = useState<string>('')

    // function to handle success change from update component
    const handleSuccessChange = (type: string) => {
        setSuccess(true);
        setSuccessType(type);

        // Hide success message after 3 seconds
        setTimeout(() => {
            setSuccess(false);
            setSuccessType('');
        }, 3000);
    }

    const getData = () => {

        // show loading
        setLoading(true)

        // path of backend to get patients
        const path = `${environment.api.host}/api/patient?search=${searchText}`;

        // get patients
        axios.get(path, {
            headers: {
                Authorization: `Bearer ${properties.user.access_token}`
            }
        })
            .then((response) => {

                // we add a delay so that the loader is seen
                setTimeout(() => {

                    setLoading(false)
                    const patients = response.data.items;
                    setPatients(patients)

                }, 300);

            })
            .catch((error) => {
                setLoading(false)
            })
    }

    // event that is executed when modifying the content of the search engine
    useEffect(() => {

        // get patients
        getData();

    }, [searchText])


    return <div className="DirectoryComponent Modal">

        <div className='Header'>
            {t('directory.title')}
            {
              userSettings.is_doctor && <span className='add' onClick={() => { setScreen('insert') }}>
                  {t('directory.add')}
              </span>
            }
            <i
                className='las la-times'
                onClick={() => { properties.setScene(undefined) }}
            ></i>
        </div>

        <div
            style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                overflowY: 'hidden'
            }}
        >
            <LoaderComponent
                status={loading}
            />

            {
                // success message
                success && <div className='Success'>
                    <i className="las la-check"></i>
                    <p>{t(`directory.${succesType}.success`)}</p>
                </div>
            }

            <div className='Search'>
                <i className="las la-search" />
                <input
                    type="text"
                    placeholder={`${t('directory.search')}`}
                    value={search}
                    onChange={(event) => {
                        setSearch(event.target.value)
                    }}
                />
            </div>
            {
                !loading && patients.length === 0 && <div className='Empty'>
                    <i className="las la-grin-beam-sweat"></i>
                    <p>{t('directory.empty')}</p>
                </div>
            }
            <ul className='List'>
                {
                    patients.map((patient, index) => {
                        return <li key={`${index}`}>
                            <i
                                className='las la-pen'
                                onClick={() => {
                                    setPatientSelected(patient);
                                    setScreen('update')
                                }}
                            />
                            <div className="user">
                                <div className="name">
                                    {patient.name} {patient.surnames}
                                </div>
                                <div className="document">
                                    {patient.identification}
                                </div>
                            </div>
                            <i
                                onClick={() => {
                                    setPatientSelected(patient);
                                    setScreen('delete')
                                }}
                                className='las la-trash-alt'
                            />
                        </li>
                    })
                }
            </ul>
        </div>
        {
            // show update component
            screen === 'update' && patientSelected && <DirectoryUpdateComponent
                user={properties.user}
                setScreen={setScreen}
                updateData={getData}
                patient={patientSelected}
                onSuccesChange={handleSuccessChange}
            />
        }
        {
            // show insert component
            screen === 'insert' && <DirectoryInsertComponent
                user={properties.user}
                setScreen={setScreen}
                updateData={getData}
                onSuccesChange={handleSuccessChange}
            />
        }
        {
            // show delete component
            screen === 'delete' && patientSelected && <DirectoryDeleteComponent
                user={properties.user}
                id={patientSelected.id}
                setScreen={setScreen}
                updateData={getData}
                onSuccesChange={handleSuccessChange}
            />
        }
    </div>

}
