// Modules
import { useState } from 'react'
import { Socket } from 'socket.io-client'

// Styles
import './form.styles.scss'
import { useTranslation } from 'react-i18next';

/**
 * Form Component
 * @description Component to send messages
 * @returns {JSX.Element}
 */
export function FormComponent({
    socket,
    room,
    sendMessage
}: { socket?: Socket, room?: string, sendMessage: (message: string) => void }): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // message to send
    const [message, setMessage] = useState('')

    return <div className="FormDishComponent">
        <textarea className='textarea'
            placeholder={`${t('dish.chat.form.placeholder')}`}
            autoCorrect='off'
            onChange={(event: any) => setMessage(event.target.value)}

            onKeyDown={
                (event: any) => {

                    // send message in return
                    if (event.key === 'Enter') {

                        if (message.length > 0) {

                            if (message.trim().length > 0) {

                                event.preventDefault()
                                event.stopPropagation()
                                sendMessage(message)
                                setMessage('')

                            }

                        }

                    }

                }
            }
            value={message}
        />
        <div className='bottom'>
            <div className='button' onClick={() => {

                if (message.length > 0) {

                    if (message.trim().length > 0) {

                        sendMessage(message)
                        setMessage('')

                    }

                }

            }}>
                <i className='las la-arrow-right'></i>
            </div>
        </div>
    </div>

}