// Modules
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDebounce } from 'use-debounce';

// Components
import { LoaderComponent } from '../loader/loader.component';
import { ScheduleInsertComponent } from './schedule.insert';

// Types
import { UserType } from '../../types/user.type';
import { PatientType } from '../directory/directory.types';

// Environment variables
import { environment } from '../../environment';
import { useTranslation } from 'react-i18next';

/**
 * Meet Patient Component
 * @description Screen to select patient of a meet
 * @param properties
 * @returns {JSX.Element}
 */
export function MeetPatientComponent(properties: {
    setScreen: (screen: string) => void,
    user: UserType
    openParticipants: (meet: any) => void,
    updateData: () => void
}): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // patient selected
    const [patientSelected, setPatientSelected] = useState<any>();

    // list of patients
    const [patients, setPatients] = useState<PatientType[]>([]);

    // search text
    const [search, setSearch] = useState<string>('');

    // loading status
    const [loading, setLoading] = useState<boolean>(false);

    // search text debounced
    const [searchText] = useDebounce(search, 1000);

    // jumpstep status
    const [jumpstep, setJumpstep] = useState<boolean>(true);

    const getData = () => {

        // show loading
        setLoading(true)

        // path of backend to get patients
        const path = `${environment.api.host}/api/patient?search=${searchText}`;

        // get patients
        axios.get(path, {
            headers: {
                Authorization: `Bearer ${properties.user.access_token}`
            }
        }).then((response) => {

            // delay to hide loading
            setTimeout(() => {

                // hide loading
                setLoading(false)

                // set patients
                setPatients(response.data.items)

            }, 1000);

        })
            .catch((error) => {

                // hide loading
                setLoading(false)

            })
    }
    useEffect(() => {

        // get patients with search text
        getData();

    }, [searchText])

    return <div className="DirectoryComponent Modal">

        <div className='Header'>
            {
                t('schedule.select.patient.title')
            }
            {
              <span className='add jumpstep' onClick={() => { setJumpstep(true) }}>{t('schedule.jumpstep')}</span>
            }

            <i
                style={{
                    marginLeft: 'auto',
                }}
                className='las la-times'
                onClick={() => { properties.setScreen('list') }}
            />
        </div>
        <div
            style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                overflowY: 'auto'
            }}
        >
            <LoaderComponent status={loading} />
            <div className='Search'>
                <i className="las la-search"></i>
                <input
                    type="text"
                    placeholder={`${t('schedule.select.patient.search')}`}
                    value={search}
                    onChange={(event) => {
                        setSearch(event.target.value)
                    }}
                />
            </div>
            {
                !loading && patients.length === 0 && <div className='Empty'>
                    <i className="las la-grin-beam-sweat"></i>
                    <p>{t('schedule.select.patient.empty')}</p>
                </div>
            }
            <ul className='List'>
                {
                    patients.map((patient, index) => {
                        return <li
                            key={index}
                            className="clicable"
                            onClick={() => setPatientSelected(patient)}
                        >
                            <i className='las la-user-injured' />
                            <div className="user">
                                <div className="name">
                                    {patient?.name} {patient?.surnames}
                                </div>
                                <div className="document">
                                    {patient.identification}
                                </div>
                            </div>
                        </li>
                    })
                }
            </ul>
        </div>
        {
            patientSelected && <ScheduleInsertComponent
                user={properties.user}
                setScreen={(screen) => {
                    properties.setScreen(screen)
                }}
                openParticipants={(meet) => {
                    properties.openParticipants(meet)
                }}
                deselect={() => {
                    setPatientSelected(undefined)
                }}
                updateData={properties.updateData}
                patient={patientSelected}
            />
        }
        {
            jumpstep && <ScheduleInsertComponent
                user={properties.user}
                setScreen={(screen) => {
                    properties.setScreen(screen)
                }}
                openParticipants={(meet) => {
                    properties.openParticipants(meet)
                }}
                deselect={() => {
                    setPatientSelected(undefined)
                }}
                updateData={properties.updateData}
            />
        }
    </div>

}
