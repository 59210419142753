// Properties
import { DownloadFileProperties } from "./download-file.properties"
import { useTranslation } from "react-i18next";
import './download-file.styles.scss';
import { use } from "i18next";
import { useEffect } from "react";

/**
 * DownloadFile Component
 * @description Component to show the popup to download a file
 * @param {DownloadFileProperties} properties
 * @returns {JSX.Element}
 */
export function DownloadFileComponent(properties: DownloadFileProperties): JSX.Element {
    // translation function
    const { t } = useTranslation();

    const downloadFile = () => {
      const base64Data = properties.file;
      const fileName = properties.filename;

      // Download file
      const element = document.createElement('a');
      element.setAttribute('href', base64Data);
      element.setAttribute('download', fileName);
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
      properties.setScene(undefined);
    };

    const getClass = () => {
      let returnedClass = 'las ';
      const mimeType = properties.file.split(';')[0].split(':')[1];

      if (!mimeType) {
        return returnedClass + 'la-file';
      } else if (mimeType.includes('image')) {
        return returnedClass + 'la-file-image';
      } else if (mimeType.includes('application')) {
          switch (mimeType) {
            case 'application/pdf':
              returnedClass += 'la-file-pdf';
              break;
            case 'application/vnd.oasis.opendocument.text':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/msword':
              returnedClass += 'la-file-word';
              break;
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.oasis.opendocument.spreadsheet':
              returnedClass += 'la-file-excel';
              break;
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            case 'application/vnd.oasis.opendocument.presentation':
              returnedClass += 'la-file-powerpoint';
              break;
            default:
              returnedClass += 'la-file';
              break;
          }
      } else {
        returnedClass += 'la-file';
      }

      return returnedClass;
    }

    const rejectDownload = () => {
      properties.setScene(undefined);
    };

    return <div className="DownloadFileComponent Modal Right" style={{
        right: 0,
        left: 'auto'
    }}>
        <div className='Header'>
            {t('download.title', { username: properties.username })}
        </div>

        <div className='download-message'>
            {t('download.message', { username: properties.username })}
            <div style={{ marginTop: 25, display: 'flex', alignItems: 'center' }}>
              <i className={getClass()}></i> <span>{properties.filename}</span>
            </div>
        </div>

        <div className="download-buttons">
            <button className="Submit" onClick={downloadFile}>
                {t('download.accept')}
            </button>
            <div className="Submit secondary" onClick={rejectDownload}>
                {t('download.reject')}
            </div>
        </div>
    </div>
}
