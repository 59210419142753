// Modules
import { useEffect } from "react";

// Properties
import { ForceProperties } from "./force.properties";
import { useTranslation } from "react-i18next";

// Audio Assets
const boopSfx = require('./call.mp3');

/**
 * Force Component
 * @description Component to render force to close session
 * @param {ForceProperties} properties
 * @returns {JSX.Element}
 */
export function ForceComponent(properties: ForceProperties) {

    // translation function
    const { t } = useTranslation();

    useEffect(() => {
        const audio = new Audio(boopSfx);
        audio.play();
    }, [])

    const closeRemoteSession = () => {

        properties.setForce(false)
        properties.socket.emit('user:session:close', {
          user: properties.user,
        })

    }
    return <>
        <div className="Blocked" />
        <div className="CallComponent Modal" style={{
            right: 0,
            left: 'auto'
        }}>
            <div className='Header'>
                {t('force.title')}
                <i className='las la-times last' onClick={properties.closeSession}></i>
            </div>

            <div style={{
                color: '#666',
                padding: 25, fontSize: 18, marginTop: 25, borderLeft: '4px solid #ddd', marginLeft: 25
            }}>
                {
                    t('force.description')
                }
            </div>

            <div style={{
                margin: 25, marginTop: 0
            }}>

                <div className="Submit" onClick={closeRemoteSession}>
                    {
                        t('force.yes')
                    }
                </div>
                <div className="Submit secondary" onClick={properties.closeSession}>
                    {
                        t('force.no')
                    }
                </div>
            </div>
        </div>
    </>
}
