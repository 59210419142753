// Modules
import { useState, useRef, useEffect } from 'react';

// Functions
import { SocketInstance, SocketParticipants } from './functions/socket.instance';
import { DeviceFilterType, DeviceInstance } from './functions/devices.instance';

// Components
import { SectionComponent } from './components/sections/sections.component';
import { ResizeComponent } from './components/room/resize/resize.component';
import { CamerasComponent } from './components/room/cameras/cameras.component';
import { CreatorComponent } from './components/creator/creator.component';
import { ErrorComponent } from './components/error/error.component';
import { ParticipantsComponent } from './components/participants/participants.component';
import { CallComponent } from './components/call/call.component';
import { ForceComponent } from './components/force/force.component';

// Functions
import DetectRTC from './functions/computer.detect';

// Properties
import { DishProperties } from './dish.properties';

// Types
import { AvailableType, CallType, ConnectDeviceOptions, DishParticipants } from './dish.types';
import { ChatComponent } from './components/room/chat/chat.component';

// Styles
import './dish.styles.scss'
import 'react-toastify/dist/ReactToastify.css';
import { ChatMessageType } from './components/room/chat/chat.types';
import SelectorStreams from './components/selectorStreams/selectorStreams.component';
import { UserHelper } from '../../helpers/UserHelper';
import { UserSettingsHelper } from '../../helpers/UserSettingsHelper';
import { UserSettingsType } from '../../types/user_settings.type';
import { EditProfileComponent } from './components/editProfile/editProfile.component';
import { TimeComponent } from './components/time/time.component';
import { UserInfoComponent } from './components/userInfo/userInfo.component';
import DishVideoRecording from './components/room/cameras/video/components/recording/dish.video.recording';
import { ShareFileComponent } from './components/shareFile/share-file.component';
import { DownloadFileComponent } from './components/downloadFile/download-file.component';
import Modal from '../modals/modal.component';
import useIdleTimer from '../../hooks/useIdleTimer';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

// Assets
const boopSfx = require('./call.mp3');

/**
 * Dish Component
 * @description Sistema de videoconferencia
 * @param {DishProperties} properties
 * @returns {JSX.Element}
 */
export function DishComponent(properties: DishProperties): JSX.Element {
  const { t } = useTranslation();

  // random username
  const userName = Math.random().toString(36).substring(7);

  // socket Status:
  const [socketStatus, setSocketStatus] = useState<'disconnected' | 'connected' | 'error'>('disconnected');

  // reference of socket instance
  const socketRef = useRef<SocketInstance>();

  // users availables
  const [availables, setAvailables] = useState<AvailableType[]>([])

  // call to receive new rooms
  const [call, setCall] = useState<CallType>()

  // force close session
  const [force, setForce] = useState<boolean>(false)

  // devices
  const deviceRef = useRef<DeviceInstance>();

  // streams
  const [localScreams, setLocalScreams] = useState<MediaStream[]>([]);

  // participants
  const [remoteParticipants, setRemoteParticipants] = useState<DishParticipants>({});

  // Remote Streams for remote participants
  const [remoteStreams, setRemoteStreams] = useState<{
    streamId: string,
    trackId: string,
    trackStatus: boolean,
    trackType: 'audio' | 'video'
  }[]>([]);

  // streaming Configuration
  const [audioOutput, setAudioOutput] = useState<string | undefined>()

  // client inactivity
  const [clientInactivity, setClientInactivity] = useState<string>('');

  // chat messages
  const [messages, setMessages] = useState<ChatMessageType[]>([]);

  // last messages
  const [lastMessages, setLastMessages] = useState<ChatMessageType[]>([]);

  // layout selected
  const [layout, setLayout] = useState<string>('gallery');

  // room of videoconference
  const [room, setRoom] = useState<string | undefined>();

  // Idle time
  const isUserIdle = useIdleTimer(clientInactivity, room);

  // permissions
  const [permissions, setPermissions] = useState<typeof DetectRTC>()

  // video status
  const [videoStatus, setVideoStatus] = useState<boolean>(true);

  // audio status
  const [audioStatus, setAudioStatus] = useState<boolean>(true);

  // local call
  const [localCall, setLocalCall] = useState<boolean>(false);

  // streams type map
  const [streamsTypeMap, setStreamsTypeMap] = useState<Map<string, string>>(new Map());

  // user accepted call
  const [userAcepptedCall, setUserAcepptedCall] = useState<string>('');

  // user rejected call
  const [userRejectedCall, setUserRejectedCall] = useState<string>('');

  // can record
  const [canRecord, setCanRecord] = useState<boolean>(false);

  // devices loaded
  const [devicesLoaded, setDevicesLoaded] = useState<boolean>(false);

  // answered call
  const [answeredCall, setAnsweredCall] = useState<boolean>(false);

  // clicked users
  const [clickedUsers, setClickedUsers] = useState<string[]>([]);

  // selected video
  const [selectedVideo, setSelectedVideo] = useState<boolean>(false);

  // is calling
  const [isCalling, setIsCalling] = useState<boolean>(false);

  const [showLogout, setShowLogout] = useState<boolean>(false);

  const [configOption, setConfigOption] = useState<string>('');

  const [estetoVolume, setEstetoVolume] = useState<number>(40);
  const [currentVolume, setCurrentVolume] = useState<number>(40);

  // Shared file
  const [username, setUsername] = useState<string>('');
  const [file, setFile] = useState<string>('');
  const [filename, setFilename] = useState<string>('');

  const [streamTrack, setStreamTrack] = useState<MediaStreamTrack | undefined>();

  const [concurrentCalls, setConcurrentCalls] = useState<CallType[]>([]);

  const [appsBeingUsed, setAppsBeingUsed] = useState<{ name: string, stream: MediaStream }[]>([]);

  const removeAppStream = (streamId: string) => {
    setAppsBeingUsed((prev) => prev.filter((app) => app.stream.id !== streamId));
  };


  const ringTone = () => {
    const audio = new Audio(boopSfx);
    audio.play();
  }

  useEffect(() => {
    socketRef.current?.emit('stream:change_volume', {
      volume: estetoVolume,
      room: room,
    });
  }, [estetoVolume]);

  useEffect(() => {
    if (clickedUsers.length > 0 && !isCalling) {
      setIsCalling(true);
    } else if (clickedUsers.length === 0 && isCalling) {
      setIsCalling(false);
    }
  }, [clickedUsers]);

  useEffect(() => {
    if (isCalling) {
      ringTone();
    }

    const ringInterval = setInterval(() => {
      ringTone();
    }, 3000);

    if (!isCalling) {
      clearInterval(ringInterval);
    }

    return () => clearInterval(ringInterval);
  }, [isCalling]);

  useEffect(() => {

    UserSettingsHelper.getUserSettings(properties.user)
    .then((userSettings) => {

      if (userSettings) {
        properties.setUserSettings(userSettings)
        localStorage.setItem('user_settings', JSON.stringify(userSettings))
      }
    })
    .catch((error) => {
      console.error("[App] Error getting user settings", error);

      const userSettings = localStorage.getItem('user_settings')
      if (userSettings) properties.setUserSettings(JSON.parse(userSettings) as UserSettingsType)
    });


    DetectRTC.load(function () {

      setPermissions(DetectRTC)
      DetectRTC.DetectLocalIPAddress((_ip: string) => {

      })

    });

  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      localScreams.forEach((stream, index) => {
        if (!stream.active) {
          setLocalScreams((prev) => {
            prev.splice(index, 1);
            return [...prev];
          });
        }
      });
    }, 1000);

    return () => clearInterval(interval);

  }, [localScreams, room])


  const closeSession = () => {
    // disconnect socket
    socketRef.current?.disconnect()

    // remove participants
    socketRef.current?.removeParticipants();

    // reset scene
    properties.setScene('')

    // remove remote participants
    setRemoteParticipants({})

    // clean local streams
    setLocalScreams([])

    // delete user
    properties.setUser(undefined)

    // redirect to home to avoid video caché
    window.location.href = '/';

  }

  useEffect(() => {

    if (properties.scene === 'chat') {

      // reset the last messages
      setLastMessages([])

    }

  }, [properties.scene])

  useEffect(() => {
    if (concurrentCalls.length > 0) {
      setCall(concurrentCalls[0]);
    } else {
      setCall(undefined);
    }
  }, [concurrentCalls])

  const rejectCallOnLocal = (call: CallType) => {
    setConcurrentCalls((prev) => prev.filter((c) => c.available.user.id !== call.available.user.id));
  }

  const acceptCallOnLocal = (acceptedCall: CallType) => {
    setConcurrentCalls((prev) => {
      prev.forEach((concurrentCall) => {
        if (concurrentCall.available.user.id !== acceptedCall.available.user.id) {
          socketRef.current?.emit('call:rejected', {
            room: concurrentCall.room,
            available: concurrentCall.available
          });

          toast(`Llamada perdida de ${concurrentCall.available.user.name} ${concurrentCall.available.user.surnames}`, {
            type: 'error',
            autoClose: false,
          });
        }
      });

      return [];
    });
  }

  const createSocket = () => {

    // permissions
    DeviceInstance.getPermissions()

    // Create Instance of Devices
    const devices = new DeviceInstance();
    deviceRef.current = devices;

    // Create Instance of Socket
    const instance = new SocketInstance({
      debug: true,
      userId: properties.user.id,
      user: properties.user,
      roomId: room,
      userName: userName,
      localCall: localCall,
      askCloseSession: (ask) => {

        // show popup to close session
        setForce(ask)

      },
      closeSession: () => {
        closeSession()
      },
      onConnect: async () => setSocketStatus('connected'),
      onDisconnect: () => setSocketStatus('disconnected'),
      onErrorConnecting: () => setSocketStatus('error'),
      onNewLocalStream: (stream: MediaStream, type?: {type: string}) => setLocalScreams((prev) => {

        console.log('New local stream ', stream, type);

        setStreamsTypeMap((prev) => {
          if (type) {
            prev.set(stream.id, type?.type);
          }

          return prev;
        });

        return [...prev, stream]
      }),
      onRemoveLocalStream: (streamIndex: number) => {
        setLocalScreams((prev) => {
          prev.splice(streamIndex, 1)
          return [...prev]

        })

      },
      onMessage: (message: ChatMessageType) => {
        setMessages((prev) => [...prev, message])
        if (properties.scene === 'chat') setLastMessages([])
        else setLastMessages((prev) => [...prev, message])
      },
      onAvailables: (availables: AvailableType[]) => {
        setAvailables(availables)
      },
      onRoom: (room: string) => {
        setRoom(room)
        socketRef.current?.setRoom(room)
        instance.removeParticipants();
        instance.joinRoom(room);
        setAppsBeingUsed([]);
      },

      onCall: (call: CallType) => {
        setConcurrentCalls((prev) => [...prev, call]);
      },
      onCanceled: (canceled: CallType) => {
        toast(`Llamada perdida de ${canceled.available.user.name} ${canceled.available.user.surnames}`, {
          type: 'error',
          autoClose: false
        });

        setConcurrentCalls((prev) => prev.filter((c) => c.available.user.id !== canceled.available.user.id));
      },
      reconnectToRoom: (room: string) => {
        // functionality removed by client
      },
      onRejected: (rejected: CallType) => {
        setUserRejectedCall(rejected.available.user.id);
      },
      onAccepted: (accepted: CallType) => {
        setUserAcepptedCall(accepted.available.user.id);
      },

      onUpdateParticipants: (newParticipants: SocketParticipants): void => {

        // clone participants
        const cloneParticipants: DishParticipants = {};

        // add new participants
        newParticipants && Object.keys(newParticipants) && Object.keys(newParticipants)?.map((id) => {

          cloneParticipants[id] = {
            id: id,
            user: newParticipants[id].user,
            streams: newParticipants[id]?.streams
          };

        });

        // update participants
        setRemoteParticipants(cloneParticipants);

      },
      onRemoveStreamParticipant: (streamId: string) => {

        // remove stream of participant
        setRemoteParticipants((prev) => {

          // search stream of participant and remove
          Object.keys(prev).map((id) => {

            prev[id].streams = prev[id].streams.filter((stream) => stream.id !== streamId);

          });

          return { ...prev }

        })

      },

      onChangeLayout: (layout: string) => {
        setLayout(layout);
      },

      onRemoveStream: (data: any) => {
        removeAppStream(data.streamId);
        setRemoteParticipants((prev) => {
            Object.keys(prev).map((id) => {

              prev[id].streams = prev[id].streams.filter((stream) => stream.id !== data.streamId);

            });

            return { ...prev }
         })

         setSelectedVideo(false);
      },

      onChangeStreamStatus: (streamId: string, status: boolean, type: string) => {
        setRemoteParticipants((prev) => {

          Object.keys(prev).map((id) => {

            prev[id].streams = prev[id].streams.map((stream) => {

              if (stream.id === streamId) {

                switch (type) {
                  case 'audio':
                    stream.getAudioTracks().map((track) => track.enabled = status);
                    break;
                  case 'video':
                    stream.getVideoTracks().map((track) => track.enabled = status);
                    break;
                }
              }

              return stream;

            });

          });

          return { ...prev }

        });
      },
      onFileShare: (username: string, file: string, filename: string) => {
        properties.setScene('file-shared');
        setUsername(username);
        setFile(file);
        setFilename(filename);
      },

      onChangeStreamTrackStatus: (streamId: string, trackId: string, status: boolean, type: 'audio' | 'video', roomId?: string, streamType?: string) => {
        // Función para actualizar los participantes
        const updateParticipants = async (prev: DishParticipants) => {
          // Clonamos el objeto prev para evitar mutaciones directas del estado
          const newParticipants = { ...prev };

          // Función para esperar a que los streams se actualicen
          const waitForStreams = (participant: any) => {

            return new Promise((resolve, reject) => {

              let tries = 0;
              const maxTries = 10;

              // Función para verificar si los streams se han actualizado
              const checkStreams = () => {

                // Si el participante no tiene streams, esperar 1000ms antes de volver a verificar
                if (participant.streams.length === 0) {

                  setTimeout(checkStreams, 1000);

                  tries++;

                  if (tries >= maxTries) {
                    reject('No streams found');
                  }

                } else { // Si el participante tiene streams, actualizar los tracks
                  participant.streams.forEach((stream: MediaStream) => {
                    if (stream.id === streamId) {
                      setStreamsTypeMap((prev) => {
                        streamType && prev.set(stream.id, streamType);
                        return prev;
                      });

                      switch (type) {
                        case 'audio':
                          stream.getAudioTracks().forEach((track) => {
                            if (track.id === trackId) {
                              track.enabled = status;
                            }
                          });
                          break;
                        case 'video':
                          stream.getVideoTracks().forEach((track) => {
                            if (track.id === trackId) {
                              track.enabled = status;
                            }
                          });
                          break;
                      }
                    } else if (streamType === 'share.screen' || streamType === 'med.device') {
                      setStreamsTypeMap((prev) => {
                        prev.set(streamId, streamType);
                        return prev;
                      });
                    }
                  });

                  resolve(true); // Resolvemos la promesa
                }
              };

              // Iniciar la verificación de los streams
              checkStreams();
            });
          };

          // Si no hay participantes, devolver el objeto vacío
          if (Object.keys(newParticipants).length === 0) {
            return newParticipants;
          }

          // Esperar a que los streams de cada participante se actualicen
          await Promise.all(Object.keys(newParticipants).map((id) => waitForStreams(newParticipants[id])));

          // Devolver el objeto actualizado
          return newParticipants;
        };

        setRemoteParticipants((prev) => {

          updateParticipants(prev)
            .then((newParticipants) => setRemoteParticipants(newParticipants));

          return prev; // Retornar el estado anterior mientras se actualiza
        });
      },

      onChangeVolume: (volume: number) => {
        setCurrentVolume(volume);
      },

      onUpdateLocalStream: (newStream: MediaStreamTrack) => {
        setStreamTrack(newStream);
      }
    });

    socketRef.current = instance;

  }

  // socket reset function
  const removeSocket = () => {

    // remove room
    socketRef.current?.setRoom(undefined)

    // disconnect socket
    socketRef.current?.disconnect();

    // remove socket instance
    socketRef.current = undefined;

    // remove device instance
    deviceRef.current = undefined;

    // clean all data
    setLocalScreams([]);
    setRemoteParticipants({});
    setMessages([]);
    setSocketStatus('disconnected');
    setRoom(undefined);
    setAvailables([]);
    setCall(undefined);
    setLayout('default');
    setAudioOutput(undefined);

  }

  useEffect(() => {

    // create socket in first render
    createSocket()

    UserHelper.updateUserLastLogin(properties.user)
      .then(() => {
        console.log(`[Login]: Login at ${new Date().toISOString()}`)
      }).catch((error) => {
          console.error('Error updating last login', error)
      });

    return () => {
      // remove socket in unmount
      removeSocket()
    }

  }, []);

  useEffect(() => {

    if (properties.room) {

      // reset the messages
      setMessages([])

      // reset the last messages
      setLastMessages([])

      // delete the streams
      localScreams.forEach((stream) => {
        socketRef.current?.emit('stream:remove_remote', {
          room: room,
          streamId: stream.id,
        });
      })

      // reset the availables
      setAvailables([]);

      // reset the remote participants
      setRemoteParticipants({})

      // remove participants of socket instance
      socketRef.current?.removeParticipants()

      // join to room
      socketRef.current?.emit('room:create', {
        room: properties.room,
        user: properties.user
      })
    }

  }, [properties.room])

  // permissions error
  if (!permissions) return <></>

  return <div
    className={`DishComponent ${properties.scene === 'selector-streams' ? 'bottom' : properties.scene === 'participants' ? 'le0ft' : ''}`}
  >
    <div className='header'>
      <div className='version'>
        <div>
          <img
              src='/images/logo_c.png'
              alt='logo'
              className='Logotype'
          />
        </div>
        <h1 className='sippar'>Sippar</h1>
      </div>
      <TimeComponent />
      <div className="info">
        {
          canRecord && room && <DishVideoRecording layout={layout} />
        }
        <UserInfoComponent
          name={properties.user?.name || ''}
          setShowLogout={setShowLogout}
          setConfigOption={setConfigOption}
          setScene={properties.setScene}
        />
      </div>
    </div>

    {
      room === undefined && socketRef.current && socketRef.current.status === 'connected' && deviceRef.current && <CreatorComponent
        setRoom={properties.setRoom}
        setScene={properties.setScene}
        setLocalCall={setLocalCall}
        localCall={localCall}
        user={properties.user}
        userSettings={properties.userSettings}
        sockerInstance={socketRef.current}
        availables={availables}
        localStreams={localScreams}
        setLocalStreams={(streams) => setLocalScreams(streams)}
        deviceInstance={deviceRef.current}
        setVideoStatus={(status) => {
          setVideoStatus(status)
          localStorage.setItem('video_status', status.toString())
        }}
        setAudioStatus={(status) => {
          setAudioStatus(status)
          localStorage.setItem('audio_status', status.toString())
        }}
        devicesLoaded={devicesLoaded}
        setDevicesLoaded={setDevicesLoaded}
      />
    }
    {
      socketStatus === 'error' && <ErrorComponent />
    }

    {socketRef.current && deviceRef.current && <SectionComponent
      showLogout={showLogout}
      setShowLogout={setShowLogout}
      setClientInactivity={setClientInactivity}
      room={room}
      setScene={properties.setScene}
      participants={remoteParticipants}
      numberParticipants={Object.keys(remoteParticipants).length}
      availables={[]}
      setUser={properties.setUser}
      lastMessages={lastMessages}
      setLastMessages={(messages) => setLastMessages(messages)}
      scene={properties.scene}
      setLocalCall={setLocalCall}
      localCall={localCall}
      socket={socketRef.current}
      user={properties.user}
      userSettings={properties.userSettings}
      videoStatus={videoStatus}
      audioStatus={audioStatus}
      setVideoStatus={(status: boolean) => {
          setVideoStatus(status)
          localStorage.setItem('video_status', status.toString())
        }
      }
      setAudioStatus={(status: boolean) => {
          setAudioStatus(status)
          localStorage.setItem('audio_status', status.toString())
        }
      }
      layout={layout}
      setAudioOutputDevice={(deviceId) => setAudioOutput(deviceId)}
      audioOutputDevice={audioOutput}
      messages={messages}
      setLayout={(layout) => {
        setLayout(layout)
      }}
      socketStatus={socketStatus}
      localScreams={localScreams}
      getAudioOutputDevices={async () => await deviceRef.current?.getAudioOutput()}
      getDevices={async (filter?: DeviceFilterType) => await deviceRef.current?.getDevices(filter)}

      connectDevice={async (devices: { videoId: string } | { audioId: string } | { videoId: string, audioId: string }, options: ConnectDeviceOptions) => {

        const stream = await deviceRef.current?.getDeviceMedia(devices, {
          ...options
        });
        stream && socketRef.current?.addLocalStream(stream, {type: 'media'});

        return stream;

      }}
      connectStream={(stream: MediaStream) => {

        stream && socketRef.current?.addLocalStream(stream, {type: 'media'});

        return stream;

      }}
      sendMessage={(message: string) => socketRef?.current?.sendMessage(message)}
      getStreenMedia={async () => {

        const screenMedia = await deviceRef.current?.getScreenMedias();
        screenMedia && socketRef.current?.addLocalStream(screenMedia, {type: 'screen'});



      }}

      finishCall={() => {

        if (localScreams) {
          const rr = localScreams.map((stream) => stream.getTracks().map((track) => track.stop()));
        }

        removeSocket()

        properties.setScene();
        socketRef.current?.setRoom(undefined)
        properties.room && properties.setRoom();
        createSocket()

      }}
      deviceInstance={deviceRef.current}
      addStream={(stream, type) => {
        socketRef.current?.addLocalStream(stream, type)
      }}
      removeShareScreen={(stream) => {
        socketRef.current?.removeShareScreen(stream)
      }}
      answeredCall={answeredCall}
      setAnsweredCall={setAnsweredCall}
      clickedUsers={clickedUsers}
      setClikedUsers={setClickedUsers}
    />}
    {
      room && socketRef.current && properties.scene === 'participants' && <ParticipantsComponent
        setScene={properties.setScene}
        availables={availables}
        socket={socketRef.current}
        room={room}
        user={properties.user}
        participants={remoteParticipants}
        rejectedCall={userRejectedCall}
        setUserRejectedCall={setUserRejectedCall}
        acceptedCall={userAcepptedCall}
        setUserAcceptedCall={setUserAcepptedCall}
        clickedUsers={clickedUsers}
        setClickedUser={setClickedUsers}
      />
    }
    {
      room && socketRef.current && properties.scene === 'file' && <ShareFileComponent
        room={room}
        setScene={properties.setScene}
        user={properties.user}
        participants={remoteParticipants}
        socket={socketRef.current}
      />
    }
    {
      socketRef.current && properties.scene === 'edit-profile' &&
      <EditProfileComponent
        setScene={properties.setScene}
        userInfo={properties.user}
        setUser={properties.setUser}
        configOption={configOption}
      />
    }
    {
      room && socketRef.current && properties.scene === 'chat' && <ChatComponent
        setSection={() => {
          setLastMessages([])
          properties.setScene()
        }}
        messages={messages}
        sendMessage={(message: string) => socketRef?.current?.sendMessage(message)}
        room={room}
      />
    }
    {
      socketRef.current && room && deviceRef.current && <CamerasComponent
        userSettings={properties.userSettings}
        streamsTypeMap={streamsTypeMap}
        socketInstance={socketRef.current}
        localScreams={localScreams}
        remoteParticipants={remoteParticipants}
        deviceInstance={deviceRef.current}
        layout={layout}
        videoStatus={videoStatus}
        audioStatus={audioStatus}
        room={room}
        setAudioStatus={(status) => { setAudioStatus(status) }}
        setVideoStatus={(status) => { setVideoStatus(status) }}
        canRecord={canRecord}
        setCanRecord={setCanRecord}
        selectedVideo={selectedVideo}
        setSelectedVideo={setSelectedVideo}
        estetoVolume={currentVolume}
        setEstetoVolume={setEstetoVolume}
        streamTrack={streamTrack}
      />
    }
    {
      socketRef.current && force && <ForceComponent
        socket={socketRef.current}
        setCall={setCall}
        setForce={setForce}
        closeSession={() => {
          setForce(false)
          closeSession();
        }}
        setRoom={(newRoom) => {
          setMessages([])
          setLastMessages([])
          // eliminamos los streams
          localScreams.forEach((stream) => {
            socketRef.current?.emit('stream:remove_remote', {
              room: room,
              streamId: stream.id,
            });
          })
          setAvailables([]);
          setRemoteParticipants({})
          socketRef.current?.removeParticipants()
          socketRef.current?.setRoom(newRoom)
          setRoom(newRoom)
          if (properties.room) {
            properties.setRoom(undefined)
          }
        }}
        user={properties.user}
      />
    }
    {
      socketRef.current && call && <CallComponent
        key={`${call.available.user.id}-${call.room}`}
        rejectCall={rejectCallOnLocal}
        acceptCall={acceptCallOnLocal}
        socket={socketRef.current}
        setCall={setCall} call={call}
        setRoom={(newRoom) => {
          setMessages([])
          setLastMessages([])
          // eliminamos los streams
          localScreams.forEach((stream) => {
            socketRef.current?.emit('stream:remove_remote', {
              room: room,
              streamId: stream.id,
            });
          })
          setAvailables([]);
          setRemoteParticipants({})
          socketRef.current?.removeParticipants()
          setRoom(newRoom)
          socketRef.current?.setRoom(newRoom)
          if (properties.room) {
            properties.setRoom(undefined)
          }
        }}
        setAnsweredCall={setAnsweredCall}
        user={properties.user}
        devicesLoaded={devicesLoaded}
      />
    }

    {
      deviceRef.current && socketRef.current &&
      (
        properties.scene === 'selector-streams'
      )

      && <SelectorStreams
        mode='media'
        localScreams={localScreams}
        addStream={(stream, type) => socketRef.current?.addLocalStream(stream, type || {type: 'media'})}
        removeStream={(streamIndex: number) => socketRef.current?.removeLocalStream(streamIndex)}
        deviceInstance={deviceRef.current}
        setScene={properties.setScene}
        user={properties.user}
        appsBeingUsed={appsBeingUsed}
        setAppsBeingUsed={setAppsBeingUsed}
      />
    }
    {
      room && socketRef.current && properties.scene === 'file-shared'
      && <DownloadFileComponent
        setScene={properties.setScene}
        username={username}
        file={file}
        filename={filename}
      />
    }
    {
      isUserIdle && <Modal
        type='warning'
        isOpen={isUserIdle}
        onClose={() => closeSession()}
        body={
          {
            title: `${t('modal.inactivity.title')}`,
            content: `${t('modal.inactivity.description')}`
          }
        }
        buttons={
          [
            {
              text: `${t('modal.inactivity.button')}`,
              class: 'close',
              onClick: () => closeSession()
            }
          ]
        }
      />
    }
    <ResizeComponent key={`${properties.scene}-${Object.keys(remoteParticipants).length}-${localScreams.length}`} />
  </div>

}
