export class DishResize {
    // Ratios
    _ratios = ['4:3', '16:9', '1:1', '1:2'];

    // Default options
    _dish = false;
    _conference = false;
    _cameras = 15;
    _margin = 10;
    _aspect = 0;
    _video = false;
    _ratio = this.ratio(); // To perfomance call here

    // Create dish
    constructor(scene = 'dish-scene', scenary = 'dish-scenary', video = 'video') {

        // Parent space to render dish
        this._scene = document.getElementById(scene);
        this._scenary = document.getElementById(scenary);
        this._videoClass = video;

        return this;

    }

    // Split aspect ratio (format n:n)
    ratio() {

        let ratio = this._ratios[this._aspect].split(':');
        return ratio[1] / ratio[0];

    }

    // Calculate dimensions
    dimensions() {

        if (this._scene) {

            this._width = this._scene.offsetWidth - this._margin * 2;
            this._height = this._scene.offsetHeight - this._margin * 2;

        }

    }

    // Resizer of cameras
    resizer(width) {

        const childrens = this._scenary.getElementsByClassName(this._videoClass);
        for (let s = 0; s < childrens.length; s++) {

            // Camera fron dish (div without class)
            let element = childrens[s];

            // Custom margin
            element.style.margin = this._margin + 'px';

            // Calculate dimensions
            element.style.width = width + 'px';
            element.style.height = width * this._ratio + 'px';

            // To show the aspect ratio in demo (optional)
            element.setAttribute('data-aspect', this._ratios[this._aspect]);

        }

    }

    resize() {

        if (this._scenary) {

            // Get dimensions of dish
            this.dimensions();

            // Loop (i recommend you optimize this)
            let max = 0;
            let i = 1;
            while (i < 5000) {

                let area = this.area(i);
                if (area === false) {

                    max = i - 1;
                    break;

                }
                i++;

            }

            // Remove margins
            max = max - this._margin * 2;

            // Set dimensions to all cameras
            this.resizer(max);

        }

    }

    // Calculate area of dish:
    area(increment) {

        let i = 0;
        let w = 0;
        let h = increment * this._ratio + this._margin * 2;
        const childrens = this._scenary.getElementsByClassName(this._videoClass);

        while (i < childrens.length) {

            if (w + increment > this._width) {

                w = 0;
                h = h + increment * this._ratio + this._margin * 2;

            }
            w = w + increment + this._margin * 2;
            i++;

        }
        if (h > this._height || increment > this._width) return false;
        else return increment;

    }
}

