import { useEffect, useState } from "react";

import './time.styles.scss';

export function TimeComponent() {
  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const formatDate = (date: Date) => {
    return date.toLocaleDateString(navigator.language, {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
    });
  };

  const formatTime = (date: Date) => {
    return date.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return <>
    <div className="TimeComponent">
      <div className='userInfo-date-hour'>
        <div className='userInfo-hour'>
          {formatTime(dateTime)}
        </div>
        <div className='userInfo-date'>
          {formatDate(dateTime)}
        </div>
      </div>
    </div>
  </>
}
