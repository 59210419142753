import { useTranslation } from "react-i18next";
import { RestoreProperties } from "./restore.properties";
import { useState, useEffect } from "react";
import axios from "axios";
import { environment } from "../../environment";
import { ErrorsType } from "../errors/errors.type";
import { ErrorsComponent } from "../errors/errors.component";
import { ClockComponent } from "../clock/clock.component";
import { LoaderComponent } from "../loader/loader.component";
import PasswordInput from "../password/password-input.component";


export function RestoreComponent(properties: RestoreProperties): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // errors of restore request
    const [errors, setErrors] = useState<ErrorsType>({})

    // password of user
    const [password, setPassword] = useState<string>('');

    // repeat password of user
    const [repeatPassword, setRepeatPassword] = useState<string>('');

    // loading of axios request
    const [loading, setLoading] = useState<boolean>(false);

    // success of restore request
    const [restoreSuccess, setRestoreSuccess] = useState<{
      success: boolean,
      message: string
    }>({ success: false, message: `No se ha restaurado la contraseña` });

    // extract forgot token
    const [token, setToken] = useState<string>('');

    // submit attempt
    const [submit, setSubmit] = useState<boolean>(false);

    const [passwordsNotMatch, setPasswordsNotMatch] = useState<boolean>(false);

    useEffect(() => {
      if (password && repeatPassword && password !== repeatPassword) {
        setErrors({
          passwordsNotMatch: [t('form.errors.passwords')]
        })
        setPasswordsNotMatch(true)
      } else {
        setErrors({})
        setPasswordsNotMatch(false)
      }
    }, [password, repeatPassword]);

    useEffect(() => {
      const url = window.location.href;
      const tokenPattern = /token=([a-f0-9]+)/;
      const match = url.match(tokenPattern);
      if (match) {
          setToken(match[1]);
      }
    }, []);

    const handleRestoreSuccess = () => {
      properties.setScene('login');
      properties.onSuccess();
    }

    const restoreSubmit = () => {
      if (passwordsNotMatch) return;

      // set submit attempt
      setSubmit(true);

      // show loading
      setLoading(true);

      // clean errors
      setErrors({});

      // login axios request
      axios.post(`${environment.api.host}/api/auth/restore`, {
        password: password,
        repeatPassword: repeatPassword,
        token: token
      }).then((response) => {

          // delay to show success
          setTimeout(() => {

              // hide loading
              setLoading(false);

              // set success
              setRestoreSuccess({
                success: true,
                message: response.data.message || `Se ha restaurado la contraseña correctamente`
              });

              handleRestoreSuccess();

          }, 1000)
        }).catch((error) => {

            // hide loading
            setLoading(false);

            // get errors
            const errors = error?.response?.data?.errors;

            // set request errors
            if (errors) setErrors(errors);

            // define general error
            else setErrors({ general: ['Error de conexión'] });
        })
    }

    return (
        <div className='ForgotComponent'>

          <div className='box'>
            <h2>{t('restore.title')} <b>Sippar</b> </h2>

            <ErrorsComponent errors={errors} name="general" />

            <PasswordInput name={'password'} providers={[ { name: 'local' } ]} placeholder={t('restore.password')} setPassword={setPassword} checkPassword={true} submitAttempt={submit}/>
            <ErrorsComponent errors={errors} name="password" />

            <PasswordInput name={'repeatPassword'} providers={[ { name: 'local' } ]} placeholder={t('restore.repeatPassword')} setPassword={setRepeatPassword} />
            <ErrorsComponent errors={errors} name="passwordsNotMatch" />

            <ErrorsComponent errors={errors} name="passwordMatch" />
            <ErrorsComponent errors={errors} name="expiredToken" />

            <div className='Submit' style={{ scale: "0.8" }} onClick={restoreSubmit}>
              {t('restore.submit')}
            </div>

            <div className='Alternative link' onClick={() => {
                  properties.setScene('login')
              }}>

              {t('forgot.login')}

            </div>
            <LoaderComponent status={loading} />
          </div>

          {/* Clock to Render in Home Screen */}
          <ClockComponent />
        </div>
    )
}
