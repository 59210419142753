// Modules
import { useRef, useState } from 'react';
import axios from 'axios';
import 'moment/locale/es';
import moment from 'moment';

// Types
import { UserType } from '../../types/user.type';
import { ErrorsType } from '../errors/errors.type';

// Components
import { ErrorsComponent } from '../errors/errors.component';
import { AccordionComponent } from '../accordion/accordion.component';
import { LoaderComponent } from '../loader/loader.component';

// Environment variables
import { environment } from '../../environment';

// Styles
import './directory.styles.scss';
import { useTranslation } from 'react-i18next';

/**
 * Directory Insert Component
 * @description Screen to insert a patient in the platform
 * @returns {JSX.Element}
 */
export function DirectoryInsertComponent(properties: {
    setScreen: (screen: string) => void
    user: UserType
    updateData: () => void
    onSuccesChange: (type: string) => void
}): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // errors in insert request
    const [errors, setErrors] = useState<ErrorsType>({})

    // random string to refresh the component
    const [random, setRandom] = useState<string>('start')

    // loading of axios request
    const [loading, setLoading] = useState<boolean>(false)

    // reference to scroll to top
    const listInnerRef = useRef<HTMLDivElement>(null);

    // data to insert
    const [data, setData] = useState<any>({
        history: []
    })

    const onSubmit = () => {

        // show loading
        setLoading(true)

        // clean errors
        setErrors({})

        // insert patient
        axios.post(`${environment.api.host}/api/patient`, {
            ...data,
            height: Number(data.height),
            weight: Number(data.weight),
        }, {
            headers: {
                Authorization: `Bearer ${properties.user.access_token}`
            }
        }).then((response) => {

            // delay to show success
            setTimeout(() => {

                // hide loading
                setLoading(false)

                // scroll to top
                listInnerRef.current?.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })

                // update data
                properties.updateData();

                // refresh component
                setRandom(Math.random().toString())

                // clean data
                setData({
                    history: []
                })

                // go to list
                properties.setScreen('list')

                // Send success to parent component
                properties.onSuccesChange('insert')

            }, 1000);
        })
            .catch((error) => {

                // delay to show errors
                setTimeout(() => {

                    // hide loading
                    setLoading(false)

                    // get errors
                    const errors = error?.response?.data?.errors;

                    // show backend errors
                    if (errors) setErrors(errors)

                    // show general error
                    else setErrors({ general: ['Error de conexión'] })

                }, 1000);
            })
    }

    return <div className="DirectoryComponent Modal">

        {/* Header of Modal Insert Patient */}
        <div className='Header'>
            <b>{t('directory.insert.title')}</b>
            <i className='las la-times' onClick={() => { properties.setScreen('list') }} />
        </div>

        {/* Form of Insert Patient */}
        <div
            className='Form'
            ref={listInnerRef}
            key={random}
            style={{
                paddingTop: 10,
            }}>
            <div className='Pair'>
                <p>{t('directory.insert.name')}*</p>
                <p>{t('directory.insert.surname')}*</p>
            </div>
            <div className='Pair'>
                <div className='Input'>
                    <input
                        type="text"
                        onChange={(event) => {
                            setData({ ...data, name: event.target.value })
                        }}
                        value={data.name}
                    />
                    <ErrorsComponent errors={errors} name="name" />
                </div>
                <div className='Input'>

                    <input
                        type="text"
                        onChange={(event) => {
                            setData({ ...data, surname: event.target.value })
                        }}
                        value={data.surname}
                    />
                    <ErrorsComponent errors={errors} name="surname" />
                </div>
            </div>

            <p>{t('directory.insert.document')}*</p>
            <div className='Input'>

                <i className="las la-id-card"></i>
                <input
                    type="text"
                    onChange={(event) => {
                        setData({ ...data, identification: event.target.value })
                    }}
                    value={data.identification}
                />

            </div>
            <ErrorsComponent errors={errors} name="identification" />

            <div className='Space'></div>

            <AccordionComponent title={t('directory.insert.information')}>
                <p>{t('directory.insert.born')}</p>
                <div className='Input'>

                    <i className="las la-birthday-cake"></i>
                    <input
                        type="date"
                        onChange={(event) => {
                            setData({ ...data, born: event.target.value })
                        }}
                        value={data.born}
                    />

                </div>
                <ErrorsComponent errors={errors} name="born" />
                <p>{t('directory.insert.occupation')}</p>
                <div className='Input'>

                    <input
                        type="text"
                        value={data.occupation}
                        onChange={(event) => {
                            setData({ ...data, occupation: event.target.value })
                        }}
                    />

                </div>
                <ErrorsComponent errors={errors} name="work" />
                <p>{t('directory.insert.observations')}</p>
                <div className='Input'>
                    <textarea
                        value={data.description}
                        onChange={(event) => {
                            setData({ ...data, description: event.target.value })
                        }}
                    />

                </div>
                <ErrorsComponent errors={errors} name="observations" />
            </AccordionComponent>
            <AccordionComponent title={t('directory.insert.history')}>
                <div className='History'>

                    {
                        data.history.map((item: any, index: number) => {
                            return <div className='HistoryItem' key={index}>

                                <p>{t('directory.insert.history.date')}</p>
                                <div className='Input'>                                    
                                    <input
                                        value={moment(item?.date).format('YYYY-MM-DD HH:mm').toString()}
                                        disabled={true}
                                        readOnly={true}                                        
                                    />
                                </div>
                                
                                <p>{t('directory.insert.history.type')}</p>
                                <div className='Input'>

                                    <select
                                        value={item?.type}
                                        onChange={(event) => {

                                            let history = data.history;
                                            history[index].type = event.target.value;
                                            setData({ ...data, history: history })

                                        }}
                                    >
                                        <option value="1">{t('directory.insert.history.type.1')}</option>
                                        <option value="2">{t('directory.insert.history.type.2')}</option>
                                        <option value="3">{t('directory.insert.history.type.3')}</option>
                                        <option value="4">{t('directory.insert.history.type.4')}</option>
                                    </select>

                                </div>
                                <p>{t('directory.insert.observations')}</p>
                                <div className='Input'>
                                    <textarea
                                        value={data.observations}
                                        onChange={(event) => {

                                            let history = data.history;
                                            history[index].observation = event.target.value;
                                            setData({ ...data, history: history })
                                        }}
                                    />

                                </div>
                                <div className='Submit delete' onClick={() => {
                                    let history = data.history;
                                    history.splice(index, 1);
                                    setData({ ...data, history: history })
                                }} >
                                    {
                                        t('directory.insert.history.delete')
                                    }
                                </div>
                            </div>
                        })
                    }
                    <div style={{
                        marginLeft: 20,
                        marginRight: 20,
                        maxWidth: 360,
                        width: '100%',                        
                        opacity: 1,
                    }} className='Submit' onClick={() => {
                        setData({
                            ...data,
                            history: [...data.history, { 
                                observation: '', 
                                type: 1, 
                                date: moment(), 
                                description: '',
                            }]
                        })
                    }}>
                        {
                            t('directory.insert.history.add')
                        }
                    </div>
                </div>
            </AccordionComponent>
            <AccordionComponent title={t('directory.insert.address')}>
                <p>{t('directory.insert.street')}</p>
                <div className='Input'>

                    <input type="text"

                        value={data.address_street}
                        onChange={(event) => {
                            setData({ ...data, address_street: event.target.value })
                        }}

                    />

                </div>
                <ErrorsComponent errors={errors} name="address_street" />
                <div className='Pair'>
                    <p>{t('directory.insert.city')}</p>
                    <p>{t('directory.insert.zip')}</p>
                </div>
                <div className='Pair'>

                    <div className='Input'>

                        <input type="text"

                            value={data.address_city}
                            onChange={(event) => {
                                setData({ ...data, address_city: event.target.value })
                            }}

                        />
                        <ErrorsComponent errors={errors} name="address_city" />
                    </div>

                    <div className='Input'>

                        <input type="text"

                            value={data.address_zip}
                            onChange={(event) => {
                                setData({ ...data, address_zip: event.target.value })
                            }}

                        />
                        <ErrorsComponent errors={errors} name="address_zip" />

                    </div>
                </div>
                <p>{t('directory.insert.country')}</p>
                <div className='Input'>

                    <input type="text"

                        value={data.address_country}
                        onChange={(event) => {
                            setData({ ...data, address_country: event.target.value })
                        }}

                    />

                </div>
                <ErrorsComponent errors={errors} name="address_country" />

            </AccordionComponent>
            <AccordionComponent title={t('directory.insert.contact')}>
                <p>{t('directory.insert.email')}</p>

                <div className='Input'>

                    <i className="las la-envelope"></i>
                    <input
                        type="text"
                        onChange={(event) => {
                            setData({ ...data, email: event.target.value })
                        }}
                        value={data.email}
                    />

                </div>
                <ErrorsComponent errors={errors} name="email" />
                <p>{t('directory.insert.phone')}</p>
                <div className='Input'>

                    <i className="las la-phone"></i>
                    <input type="text"

                        value={data.phone}
                        onChange={(event) => {
                            setData({ ...data, phone: event.target.value })
                        }}

                    />

                </div>
                <ErrorsComponent errors={errors} name="phone" />
            </AccordionComponent>
            <AccordionComponent title={t('directory.insert.phisic')}>
                <p>{t('directory.insert.genre')}</p>
                <div className='Input'>

                    <i className="las la-male"></i>
                    <select
                        onChange={(event) => {
                            setData({ ...data, genre: event.target.value })
                        }}
                    >
                        <option value="1">{t('directory.insert.genre.1')}</option>
                        <option value="2">{t('directory.insert.genre.2')}</option>
                        <option value="3">{t('directory.insert.genre.3')}</option>
                    </select>

                </div>
                <ErrorsComponent errors={errors} name="genre" />
                <p>{t('directory.insert.height')}</p>
                <div className='Input'>

                    <i className="las la-ruler-vertical"></i>
                    <input
                        type="text"
                        placeholder={`${t('directory.insert.height.placeholder')}`}
                        onChange={(event) => {
                            setData({ ...data, height: event.target.value })
                        }}
                        value={data.height}
                    />

                </div>
                <ErrorsComponent errors={errors} name="height" />
                <p>{t('directory.insert.size')}</p>
                <div className='Input'>

                    <i className="las la-weight"></i>
                    <input
                        type="text"
                        placeholder={`${t('directory.insert.size.placeholder')}`}
                        onChange={(event) => {
                            setData({ ...data, weight: event.target.value })
                        }}
                        value={data.weight}
                    />

                </div>
                <ErrorsComponent errors={errors} name="weight" />
            </AccordionComponent>
            <div className='AccordionLine'></div>
            <div className='Submit-position'>
                <div className='Submit' onClick={onSubmit} >
                    {t('directory.insert.submit')}
                </div>
            </div>
        </div>
        <LoaderComponent status={loading} />
    </div>


}