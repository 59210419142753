// Modules
import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";

// Selfie Segmentation
var selfieSegmentation: any;

/**
 * Dish Background Video
 * @description Class to add a background to a video
 * @param {MediaStream} stream
 * @param {HTMLImageElement} background
 */
export class DishBackgroundVideo {

    stream: MediaStream;
    background: HTMLImageElement;
    canvas: HTMLCanvasElement;
    context: CanvasRenderingContext2D;
    video: HTMLVideoElement;
    selfieSegmentation: SelfieSegmentation;


    constructor({ stream, background }: { stream: MediaStream, background: HTMLImageElement }) {

        // save stream and background
        this.stream = stream;
        this.background = background;

        // create canvas
        this.canvas = document.createElement('canvas');
        this.canvas.width = stream.getVideoTracks()[0].getSettings().width || 1280;
        this.canvas.height = stream.getVideoTracks()[0].getSettings().height || 720;

        // get context
        const context = this.canvas.getContext('2d');
        if (!context) throw new Error('Canvas context not found');
        this.context = context;

        // create video
        this.video = document.createElement('video');
        this.video.width = stream.getVideoTracks()[0].getSettings().width || 1280;
        this.video.height = stream.getVideoTracks()[0].getSettings().height || 720;
        this.video.muted = true;
        this.video.srcObject = stream;
        this.video.autoplay = true;

        // add video to body
        document.body.appendChild(this.video);

        // set video style
        this.video.style.position = 'fixed';
        this.video.style.right = '0';
        this.video.style.top = '0';
        this.video.style.width = `${stream.getVideoTracks()[0].getSettings().width || 1280}px`;
        this.video.style.height = `${stream.getVideoTracks()[0].getSettings().height || 720}px`;
        this.video.style.zIndex = '-9999';
        this.video.style.opacity = '0';
        this.video.style.pointerEvents = 'none';

        // selfie segmentation
        selfieSegmentation = this.selfieSegmentation = new SelfieSegmentation(
            {
                locateFile: (file: any
                ) => `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`
            });

        // set options
        this.selfieSegmentation.setOptions({
            modelSelection: 1,
        });

        // detect stream stop
        this.video.addEventListener('ended', () => { })

        // detect stream stop
        this.selfieSegmentation.onResults((results: any) => {
            this.onResults(results);
        });

    }

    onResults(results: any) {

        // clear canvas
        this.context.drawImage(
            results.segmentationMask,
            0,
            0,
            this.canvas.width,
            this.canvas.height
        );

        // Only overwrite existing pixels.
        this.context.globalCompositeOperation = "source-out";

        // draw background
        if (this.background.width) {

            // calculate scale
            const scaleWidth = this.canvas.width / this.background.width;
            const scaleHeight = this.canvas.height / this.background.height;
            const scale = scaleWidth > scaleHeight ? scaleWidth : scaleHeight;

            // Position on center
            const x = (this.canvas.width - this.background.width * scale) / 2;
            const y = (this.canvas.height - this.background.height * scale) / 2;

            // draw background
            this.context.drawImage(
              this.background,
              0, 0, this.background.width, this.background.height,
              x, y, this.background.width * scale, this.background.height * scale
          );

        }

        // Only overwrite missing pixels.
        this.context.globalCompositeOperation = "destination-atop";

        // Position on center
        const resultX = (this.canvas.width - results.image.width) / 2;
        const resultY = (this.canvas.height - results.image.height) / 2;

        // draw image
        this.context.drawImage(
          results.image,
          resultX, resultY,
          results.image.width, results.image.height
      );

        // Only overwrite missing pixels.
        this.context.restore();

    }

    start() {
      const processFrame = async () => {
        if (this.video.width) {
          await this.selfieSegmentation.send({ image: this.video });
          requestAnimationFrame(processFrame); // Solicitud de renderizado del siguiente frame
        }
      };

      processFrame();
    }

    stop() {

        // close selfie segmentation
        this.selfieSegmentation.close();

        // stop segmentation
        selfieSegmentation?.close();

    }

    run() {

        // start
        setTimeout(async () => {
            this.start()
        }, 1000);

    }

    async getStream() {

        // get stream
        const streamResult = this.canvas.captureStream(25);

        // add audio
        const [audioTrack] = this.stream.getAudioTracks();

        // add audio track
        if (audioTrack) streamResult.addTrack(audioTrack);

        // return stream
        return streamResult;

    }

}

