// Modules
import { useEffect, useState } from 'react';
import { t } from 'i18next';

// Styles
import './joystick.dish.styles.scss';

// Properties
import { ControlsButtonVideoType } from '../../dish.video.controls.types';
import { useTranslation } from 'react-i18next';
interface JoystickDishProperties {
    stream: MediaStream;
    onPTZ: (detect: boolean) => void;
    renderButton: (settings: ControlsButtonVideoType) => JSX.Element|undefined;
    streamTrack?: MediaStreamTrack | undefined;
}

/**
 * Joystick Dish Component
 * @description Component to control a camera
 * @param {JoystickDishProperties} properties
 * @returns {JSX.Element}
 */
export const JoystickDishComponent = ({ stream, onPTZ, renderButton, streamTrack}: JoystickDishProperties): JSX.Element | null => {

    // zoom control
    const [maxZoom, setMaxZoom] = useState<number>(0);
    const [minZoom, setMinZoom] = useState<number>(0);
    const [zoom, setZoom] = useState<number>(0);
    const [zoomStatus, setZoomStatus] = useState<number>(0);

    // pan control
    const [maxPan, setMaxPan] = useState<number>(0);
    const [minPan, setMinPan] = useState<number>(0);
    const [pan, setPan] = useState<number>(0);
    const [panStatus, setPanStatus] = useState<number>(0);

    // tilt control
    const [maxTilt, setMaxTilt] = useState<number>(0);
    const [minTilt, setMinTilt] = useState<number>(0);
    const [tilt, setTilt] = useState<number>(0);
    const [tiltStatus, setTiltStatus] = useState<number>(0);

    // status
    const [status, setStatus] = useState<boolean>(false);

    const { t } = useTranslation();


    useEffect(() => {

        // get video track
        const [track] = stream.getVideoTracks();

        if (track && track.getCapabilities) {

            // get capabilities of video track
            const capabilities = track.getCapabilities();

            // get settings of video track
            const settings = track.getSettings();

            if (capabilities && settings) {

                // @ts-ignore
                setZoomStatus(capabilities?.zoom ? true : false)
                // @ts-ignore
                setPanStatus(capabilities?.pan ? true : false)
                // @ts-ignore
                setTiltStatus(capabilities?.tilt ? true : false)
                // @ts-ignore
                setMaxZoom(capabilities?.zoom?.max);
                // @ts-ignore
                setMinZoom(capabilities?.zoom?.min);
                // @ts-ignore
                setMaxPan(capabilities?.pan?.max);
                // @ts-ignore
                setMinPan(capabilities?.pan?.min);
                // @ts-ignore
                setMaxTilt(capabilities?.tilt?.max);
                // @ts-ignore
                setMinTilt(capabilities?.tilt?.min);
                // @ts-ignore
                setZoom(settings?.zoom);
                // @ts-ignore
                setPan(settings?.pan);
                // @ts-ignore
                setTilt(settings?.tilt);

            }

        }

    }, [streamTrack]);


    // if (!zoomStatus && !panStatus && !tiltStatus) {
    //     onPTZ(false);
    //     return null
    // };

    return !zoomStatus && !panStatus && !tiltStatus ? <>
    {
        onPTZ(false)
    }</> : <>
        {
            onPTZ(true)
        }

        {
            /* PTZ control button */
            renderButton({
                name: 'video.ptz.control',
                title: t('button.video.ptz.control.title').toString(),
                icon: 'las la-sliders-h',
                active: status,
                onClick: () => {
                    setStatus(!status);
                },

            })
        }

        {status && <div className="joystick">
            <i className='las la-times' onClick={(e) => {

                e.preventDefault();
                e.stopPropagation();
                setStatus(false);

            }} />
            {
                zoomStatus && <div>
                    <div className="label">{t('joystick.zoom')}</div>
                    <div className='rang'>
                        <div className='decrease'
                            onClick={() => {

                                // split zoom in steps
                                const step = (maxZoom - minZoom) / 40;
                                const newZoom = zoom - step;

                                // set new zoom
                                setZoom(newZoom);

                                // apply new zoom
                                const [track] = stream.getVideoTracks();
                                track.applyConstraints({
                                    // @ts-ignore
                                    advanced: [{ zoom: newZoom }]
                                });
                            }}
                        >
                            <i className='las la-minus' />

                        </div>
                        <input
                            className="slider"
                            type="range"
                            min={minZoom}
                            max={maxZoom}
                            value={zoom}
                            onChange={(e) => {

                                setZoom(Number(e.target.value));
                                const [track] = stream.getVideoTracks();
                                track.applyConstraints({
                                    // @ts-ignore
                                    advanced: [{ zoom: Number(e.target.value) }]
                                });

                            }}
                        />
                        <div className='increase'
                            onClick={() => {

                                // split zoom in steps
                                const step = (maxZoom - minZoom) / 40;
                                const newZoom = zoom + step;

                                // set new zoom
                                setZoom(newZoom);

                                // apply new zoom
                                const [track] = stream.getVideoTracks();
                                track.applyConstraints({
                                    // @ts-ignore
                                    advanced: [{ zoom: newZoom }]
                                });

                            }}
                        ><i className='las la-plus' /></div>
                    </div>
                </div>
            }
            {
                panStatus && <div>
                    <div className="label">{t('joystick.pan')}</div>
                    <div className='rang'>
                        <div className='decrease'
                            onClick={() => {

                                // split pan in steps
                                const step = (maxPan - minPan) / 40;
                                const newPan = pan - step;
                                setPan(newPan);
                                const [track] = stream.getVideoTracks();
                                track.applyConstraints({
                                    // @ts-ignore
                                    advanced: [{ pan: newPan }]
                                });
                            }}
                        >
                            <i className='las la-minus'></i>

                        </div>

                        <input className="slider" type="range" min={minPan} max={maxPan} value={pan} onChange={(e) => {

                            setPan(Number(e.target.value));
                            const [track] = stream.getVideoTracks();
                            track.applyConstraints({
                                // @ts-ignore
                                advanced: [{ pan: Number(e.target.value) }]
                            });

                        }} />
                        <div className='increase'

                            onClick={() => {

                                // split pan in steps
                                const step = (maxPan - minPan) / 40;
                                const newPan = pan + step;
                                setPan(newPan);
                                const [track] = stream.getVideoTracks();
                                track.applyConstraints({
                                    // @ts-ignore
                                    advanced: [{ pan: newPan }]
                                });
                            }}
                        ><i className='las la-plus'></i></div>

                    </div>
                </div>
            }
            {
                tiltStatus && <div>
                    <div className="label">{t('joystick.tilt')}</div>
                    <div className='rang'>
                        <div className='decrease'

                            onClick={() => {

                                // split tilt in steps
                                const step = (maxTilt - minTilt) / 40;
                                const newTilt = tilt - step;
                                setTilt(newTilt);
                                const [track] = stream.getVideoTracks();
                                track.applyConstraints({
                                    // @ts-ignore
                                    advanced: [{ tilt: newTilt }]
                                });
                            }}
                        >
                            <i className='las la-minus'></i>

                        </div>

                        <input className="slider" type="range" min={minTilt} max={maxTilt} value={tilt} onChange={(e) => {

                            setTilt(Number(e.target.value));
                            const [track] = stream.getVideoTracks();
                            track.applyConstraints({
                                // @ts-ignore
                                advanced: [{ tilt: Number(e.target.value) }]
                            });

                        }} />
                        <div className='increase'

                            onClick={() => {

                                // split tilt in steps
                                const step = (maxTilt - minTilt) / 40;
                                const newTilt = tilt + step;
                                setTilt(newTilt);
                                const [track] = stream.getVideoTracks();
                                track.applyConstraints({
                                    // @ts-ignore
                                    advanced: [{ tilt: newTilt }]
                                });
                            }}
                        ><i className='las la-plus'></i></div>
                    </div>
                </div>
            }

        </div>
        }
    </>

};
