import './instrument';

// Modules
import ReactDOM from 'react-dom/client';
import i18next from "i18next";
import { I18nextProvider } from 'react-i18next';

// Components
import App from './App';
import reportWebVitals from './reportWebVitals';

// Translations
import { translations } from './translations';

// init translations
i18next.init(translations);

// Create root element
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Render Sippar
root.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
);

reportWebVitals();
