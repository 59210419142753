import { useTranslation } from "react-i18next";
import { ForgotProperties } from "./forgot.properties";
import { useState, useEffect } from "react";
import { ErrorsType } from "../errors/errors.type";
import axios from "axios";
import { environment } from "../../environment";
import { ErrorsComponent } from "../errors/errors.component";
import { ClockComponent } from "../clock/clock.component";

import './forgot.styles.scss';
import { LoaderComponent } from "../loader/loader.component";
import { use } from "i18next";


export function ForgotComponent(properties: ForgotProperties): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // errors of login request
    const [errors, setErrors] = useState<ErrorsType>({})

    const [mailSent, setMailSent] = useState<{
      success: boolean,
      message: string
    }>({ success: false, message: `No se ha enviado el correo. Revisa el email` })

    // email of user
    const [email, setEmail] = useState<string>('')

    // loading of axios request
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
      if (mailSent.success) {
        properties.onSuccess()

        properties.setScene('login')
      }
    }, [mailSent])

    const forgotSubmit = () => {

        // show loading
        setLoading(true)

        // clean errors
        setErrors({})

        // login axios request
        axios.post(`${environment.api.host}/api/auth/forgot`, {
            email: email
        }).then((response) => {

            // delay to show success
            setTimeout(() => {

                // hide loading
                setLoading(false)

                // set success
                setMailSent({
                  success: true,
                  message: response.data.message || `Se ha enviado el correo a ${email}. Revisa tu bandeja de entrada.`
                })

            }, 1000)

        }).catch((error) => {

            // hide loading
            setLoading(false)

            // get errors
            const errors = error?.response?.data?.errors;

            // set request errors
            if (errors) setErrors(errors)

            // define general error
            else setErrors({ general: ['Error de conexión'] })

        })

    }

    return (
        <div className='ForgotComponent'>

          <div className='box'>
            <h2>{t('forgot.title')} <b>Sippar</b> </h2>

            <ErrorsComponent errors={errors} name="general" />

            <div className='Input'>
                  <i className="las la-envelope"></i>
                  <input type="text" placeholder={`${t('forgot.email')}`}
                    onChange={(event) => {
                        setEmail(event.target.value)
                    }}
                  />
              </div>
              <ErrorsComponent errors={errors} name="email" />

              <div className='Submit' style={{ scale: "0.8" }} onClick={forgotSubmit}>
                {t('forgot.submit')}
              </div>

              <div className='Alternative link' onClick={() => {
                    properties.setScene('login')
                }}>

                {t('forgot.login')}

              </div>
              <LoaderComponent status={loading} />
          </div>

          {/* Clock to Render in Home Screen */}
          <ClockComponent />

        </div>
    )

}

