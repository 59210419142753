// Styles
import './loader.styles.scss';

/**
 * Loader Component
 * @description Component to show a loader
 * @returns {JSX.Element}
 */
export function LoaderComponent(properties: {
    status: boolean
}): JSX.Element {

    return <div
        className={`LoaderComponent ${properties.status ? 'active' : ''}`}
    >
        <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="5" strokeMiterlimit="30" />
        </svg>
    </div>

}